import React, { useReducer, useEffect } from "react";
import { TedModal, TedButton } from "../../../../core-ui";
import { Formulaire } from "../../../../components";
import { useSelector, useDispatch } from "react-redux";
import {
  setOpenModal,
  setLoading,
  setIsSaved,
  setError,
} from "../../../../global-state/redux";

import {
  reducer,
  configFormCreateUser,
  defaultValueFormCreateUser,
} from "../../../../reducers";

import { postRequest } from "../../../../network/httpRequest";
import { urls } from "../../../../constant";

const CreateUsers = ({ statique }) => {
  const { open, number } = useSelector((store) => store.modal);
  const { isloading } = useSelector((store) => store.loading);
  const dispatch = useDispatch();
  const [configForm, dispatchConfig] = useReducer(
    reducer,
    configFormCreateUser
  );
  const [defaultValue, dispatchDefautlValue] = useReducer(
    reducer,
    defaultValueFormCreateUser
  );
  const { provinces, communes, zones, collines, localites, roles } = statique;

  const saveUser = async () => {
    dispatch(setLoading());
    const datas = defaultValue;
    const { err, msg, data, typeErr } = await postRequest(
      `${urls.user}`,
      "json",
      datas
    );

    if (typeErr === "failed") {
      dispatch(setLoading());
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    dispatch(setLoading());
    dispatch(setError({ err, msg, data, typeErr }));
    dispatch(setOpenModal({ number: 0 }));
    return dispatch(setIsSaved());
  };
  useEffect(() => {
    dispatchConfig({
      type: "SET_USER_DATA",
      payload: { provinces, roles },
    });
  }, []);
  useEffect(() => {
    dispatchConfig({
      type: "SET_DEPENDANT_COMMUNE",
      payload: { provinceId: defaultValue.provinceId, communes },
    });
  }, [defaultValue.provinceId]);
  useEffect(() => {
    dispatchConfig({
      type: "SET_DEPENDANT_ZONE",
      payload: { communeId: defaultValue.communeId, zones },
    });
  }, [defaultValue.communeId]);

  useEffect(() => {
    dispatchConfig({
      type: "SET_DEPENDANT_COLLINE",
      payload: { zoneId: defaultValue.zoneId, collines },
    });
  }, [defaultValue.zoneId]);

  useEffect(() => {
    dispatchConfig({
      type: "SET_DEPENDANT_LOCALITE",
      payload: { collineId: defaultValue.collineId, localites },
    });
  }, [defaultValue.collineId]);

  return (
    <TedModal
      title={"Ajouter un utilisateur"}
      isOpen={open}
      fxCloseAndOpen={() => dispatch(setOpenModal({ number: 0 }))}
      size="moyen"
    >
      <div className="form-create-user">
        <div>
          <Formulaire
            fields={configForm.firstOne}
            dispatch={dispatchDefautlValue}
            values={defaultValue}
            align="column"
          />
          {defaultValue.roleId === 5 && (
            <Formulaire
              fields={configForm.secondOne}
              dispatch={dispatchDefautlValue}
              values={defaultValue}
              align="column"
            />
          )}

          <Formulaire
            fields={configForm.fiveOne}
            dispatch={dispatchDefautlValue}
            values={defaultValue}
            align="column"
          />
        </div>
        <div>
          <Formulaire
            fields={configForm.thirdOne}
            dispatch={dispatchDefautlValue}
            values={defaultValue}
            align="column"
          />
        </div>
      </div>
      <div className="save-btn-modal">
        <TedButton
          type="button"
          classe="primary"
          label="ENREGISTRER"
          loading={isloading}
          fxOnClick={() => saveUser()}
        />
      </div>
    </TedModal>
  );
};

export default CreateUsers;
