import React, { useEffect, useState } from "react";
import { urls, icons } from "../../../constant";
import { useSelector, useDispatch } from "react-redux";
import {
  setError,
  setLoading,
  setIsSaved,
  setOpenModal,
  setData,
  setDropValue,
  setIsWaitingData,
} from "../../../global-state/redux";
import { getRequest } from "../../../network/httpRequest";
import { formatterDateAndHour } from "../../../helpers";
import { TedEmpty, TedDropdown, TedCheckBox } from "../../../core-ui";
import { PaginationItems, Skelton } from "../../../components";
const configHeaderTable = [
  "#",
  "Date",
  "UMVA ID",
  "Nom & Prénom",
  "UMVA Card",
  "Uban",
  "Adresse",
  "Article",
  "Qte",
  "Status",
  "Action",
];

const ListCommandes = ({ data, tabIndex }) => {
  const [dataCommande, setDataCommande] = useState({ count: 0, rows: [] });
  const {
    dataUser: {
      user: {
        auth: { roleId },
      },
    },
  } = useSelector((store) => store.user);
  const { isSaved, isloading, isWaitingData } = useSelector(
    (store) => store.loading
  );
  const { data: commandes } = useSelector((store) => store.data);
  const dispatch = useDispatch();
  const getCommandes = async (page = 1) => {
    dispatch(setIsWaitingData());
    const { err, msg, data, typeErr } = await getRequest(
      `${urls.commande}?lang=fr&page=${page}&${tabIndex.clause}`
    );
    if (err) {
      dispatch(setError({ err, msg, data, typeErr }));
      return dispatch(setIsWaitingData());
    }
    dispatch(setIsWaitingData());
    setDataCommande(data);
  };
  useEffect(() => {
    getCommandes();
    dispatch(setData([]));
  }, [isSaved, tabIndex]);

  const configdropDown = [
    {
      name: "Détails",
      icons: icons.action_details,
      fx: (item) =>
        dispatch(
          setOpenModal({
            actionName: "actionContent",
            data: item,
            actionTitle: `DETAILS DE LA COMMANDE DE ${item?.first_name} ${item?.last_name}`,
            number: 3,
          })
        ),
    },
    {
      name: "Supprimer",
      icons: icons.action_delete,
      fx: (item) =>
        dispatch(
          setOpenModal({
            actionName: "deleteContent",
            data: item,
            actionTitle: `suppression de la commande du client ${item.first_name} ${item.last_name}`,
            number: 3,
          })
        ),
    },
  ];
  const configdropDownVendeur = [
    {
      name: "Détails",
      icons: icons.action_details,
      fx: (item) =>
        dispatch(
          setOpenModal({
            actionName: "actionContent",
            data: item,
            actionTitle: `DETAILS DE LA COMMANDE DE ${item?.first_name} ${item?.last_name}`,
            number: 3,
          })
        ),
    },
  ];

  const buildDataAffectation = (idcommande) => {
    let oldCommandes = commandes;
    let newCommandes = [];
    if (oldCommandes.length < 1) {
      newCommandes = [
        {
          idcommande,
        },
      ];
    } else {
      const checkIfCommandeExist = oldCommandes.filter(
        (item) => item.idcommande === idcommande
      );
      if (checkIfCommandeExist.length > 0) {
        newCommandes = oldCommandes.filter(
          (item) => item.idcommande !== idcommande
        );
      } else {
        newCommandes = [
          ...oldCommandes,
          {
            idcommande,
          },
        ];
      }
    }
    dispatch(setData(newCommandes));
  };

  return (
    <>
      <div>
        <table className="table-normal">
          <thead>
            <tr>
              {configHeaderTable.map((i, idx) => {
                return <td key={idx}>{i}</td>;
              })}
            </tr>
          </thead>
          {!isWaitingData && (
            <tbody>
              {dataCommande &&
                dataCommande.rows.map((item) => {
                  const {
                    id,
                    umva_id,
                    first_name,
                    last_name,
                    umva_cardid,
                    uban,
                    has_umva,
                    province,
                    commune,
                    zone,
                    colline,
                    localite,
                    group,
                    commande_articles,
                    qte,
                    status,
                    vendeur_data,
                    createdAt,
                    date,
                  } = item;
                  return (
                    <tr key={id}>
                      <td>
                        <TedCheckBox
                          name={id}
                          label=""
                          fxOnchange={(e) =>
                            buildDataAffectation(e.target.name)
                          }
                          desabled={
                            status.id > 1 && roleId == 1
                              ? true
                              : status.id > 2 && roleId == 2
                              ? true
                              : false
                          }
                        />
                      </td>
                      <td>{date}</td>
                      <td>{umva_id ? umva_id : "-"}</td>
                      <td>{`${first_name} ${last_name}`}</td>
                      <td>{umva_cardid}</td>
                      <td>{uban}</td>
                      <td>{province}</td>
                      <td>
                        {commande_articles &&
                          commande_articles.translation[0]?.name}
                      </td>
                      <td>{qte}</td>
                      <td>
                        <span className={`status ${status.color}`}>
                          {status.description}
                        </span>
                      </td>
                      <td>
                        <div className="actions">
                          <TedDropdown
                            configdropDown={
                              roleId === 1
                                ? configdropDown
                                : configdropDownVendeur
                            }
                            data={item}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          )}
        </table>
        {isWaitingData && <Skelton n={0} />}
        {dataCommande.rows.length < 1 && !isWaitingData && (
          <TedEmpty description={"Aucune commande"} icon={icons.empty} />
        )}
      </div>
      <PaginationItems
        itemsPerPage={10}
        dataLength={dataCommande.count}
        changePage={getCommandes}
      />
    </>
  );
};

export default ListCommandes;
