import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setError } from "../global-state/redux";
import { getRequest } from "../network/httpRequest";

const useGetFxData = () => {
  const dispatch = useDispatch();

  const [fxData, setFxData] = useState([]);
  const [loading, seLoading] = useState(false);
  useEffect(() => {}, []);
  const getFxData = async (url) => {
    seLoading(true);
    const { err, msg, data, typeErr } = await getRequest(url);
    setTimeout(() => {
      seLoading(false);
    }, 2000);
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    setFxData(data);
  };
  return [loading, fxData, getFxData];
};

export default useGetFxData;
