import React, { useEffect, useState } from "react";
import { urls, images, icons } from "../../../constant";
import { useSelector, useDispatch } from "react-redux";
import {
  setError,
  setLoading,
  setIsSaved,
  setOpenModal,
  setData,
  setDropValue,
} from "../../../global-state/redux";
import { getRequest } from "../../../network/httpRequest";
import { formatterDateAndHour } from "../../../helpers";
import { TedEmpty, TedDropdown, TedCheckBox } from "../../../core-ui";
import { FaSmileBeam } from "react-icons/fa";
const configHeaderTable = [
  "Date",
  "UMVA ID",
  "Nom & Prénom",
  "UMVA Card",
  "Uban",
  "Type",
  "Montant",
  "Action",
];

const ListDeposit = ({ data }) => {
  const [dataTable, setDataTable] = useState([]);
  const {
    dataUser: {
      user: {
        auth: { roleId },
      },
    },
  } = useSelector((store) => store.user);
  const { isSaved } = useSelector((store) => store.loading);
  const { data: commandes } = useSelector((store) => store.data);
  const dispatch = useDispatch();
  const getDeposit = async () => {
    dispatch(setLoading());
    const { err, msg, data, typeErr } = await getRequest(`${urls.deposit}`);
    dispatch(setLoading());
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    setDataTable(data);
  };
  useEffect(() => {
    getDeposit();
  }, [isSaved]);

  const configdropDown = [
    {
      name: "Editer",
      icons: icons.action_edit,
      fx: "onEdit",
    },
    {
      name: "Supprimer",
      icons: icons.action_delete,
      fx: (item) => {},
    },
  ];

  return (
    <div>
      <table className="table-normal">
        <thead>
          <tr>
            {configHeaderTable.map((i, idx) => {
              return <td key={idx}>{i}</td>;
            })}
          </tr>
        </thead>
        <tbody>
          {dataTable &&
            dataTable.map((item) => {
              const {
                id,
                umva_id,
                first_name,
                last_name,
                umva_cardid,
                uban,
                date,
                has_umva,
                amount,
              } = item;
              return (
                <tr key={id}>
                  <td>{date}</td>
                  <td>{umva_id ? umva_id : "-"}</td>
                  <td>{`${first_name} ${last_name}`}</td>
                  <td>{umva_cardid}</td>
                  <td>{uban}</td>
                  <td>{!has_umva ? "Nouveau" : "Ancien"}</td>
                  <td>{amount}</td>
                  {/* <td>
                    <div className="actions">
                      <TedDropdown
                        configdropDown={configdropDown}
                        data={item}
                      />
                    </div>
                  </td> */}
                </tr>
              );
            })}
        </tbody>
      </table>
      {dataTable.length < 1 && (
        <TedEmpty description={"Aucune commande"} icon={icons.empty} />
      )}
    </div>
  );
};

export default ListDeposit;
