const formatterDate = (dates) => {
  const date = new Date(dates);
  const month = date.getMonth();
  const newmonth = month < 10 ? "0" + month : month;

  const day = date.getDate();
  const year = date.getFullYear();

  return day + "-" + newmonth + "-" + year;
};
const formatterHour = (dates) => {
  const date = new Date(dates);
  return date.toLocaleTimeString();
};

const formatterDateAndHour = (dates) => {
  const date = formatterDate(dates);
  const heure = formatterHour(dates);

  return `${date} ${heure}`;
};

export { formatterDate, formatterHour, formatterDateAndHour };
