import React, { useReducer } from "react";
//useHistory to access history in functional compoent
import { useHistory } from "react-router-dom";
import "./Login.css";
import { images } from "../../constant";
//config
import { config } from "../../constant";
//core-ui
import { TedButton, TedErrorPopup } from "../../core-ui";
//constant
import { urls } from "../../constant";
//network
import { postRequest } from "../../network/httpRequest";
import "../../translations/i18n";
import {
  reducer,
  defaultValueFormLogin,
  configFormLogin,
} from "../../reducers";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  setError,
  closeError,
  setLoading,
  setLoginUser,
} from "../../global-state/redux";
import { Formulaire } from "../../components";

const Login = () => {
  const { nameAppLong, welcomeMessage } = config;
  const configError = useSelector((store) => store.error);
  const { isloading } = useSelector((store) => store.loading);

  const dispatch = useDispatch();

  const history = useHistory();
  const [ValueFormLogin, dispatchValue] = useReducer(
    reducer,
    defaultValueFormLogin
  );
  const [ConfigFormLogin, dispatchConfig] = useReducer(
    reducer,
    configFormLogin
  );

  const login = async () => {
    dispatch(setLoading());
    const datas = ValueFormLogin;
    const { err, msg, data, typeErr } = await postRequest(
      urls.login,
      "json",
      datas
    );

    if (err) {
      dispatch(setError({ err, msg, typeErr }));
      dispatch(setLoading());
      return;
    }
    dispatch(setLoading());
    dispatch(setLoginUser({ dataUser: data, isConnected: true }));
    sessionStorage.setItem("xx_tk", data.token);
    history.push("/dashboard");
  };

  return (
    <>
      <div className="login">
        <div>
          <div className="title-login">
            <h1>{nameAppLong}</h1>
          </div>
          <div className="container-login">
            <h2>{welcomeMessage}</h2>
            <span>Connectez-vous avec vos identifiants de connexion</span>
            <div>
              <form>
                <Formulaire
                  fields={ConfigFormLogin}
                  dispatch={dispatchValue}
                  values={ValueFormLogin}
                  align="column"
                />
                <TedButton
                  type="button"
                  classe="primary"
                  label="SE CONNECTER"
                  loading={isloading}
                  fxOnClick={() => login()}
                />
              </form>
            </div>
          </div>
        </div>
        <div>
          <img src={images.loginImage} alt="logo images" />
        </div>
      </div>

      <TedErrorPopup
        errorsConfig={configError} //  const errors = { messageError: msg, showError: err, typeError: typeErr,};
        fxClosePopup={() => dispatch(closeError())}
      />
    </>
  );
};

export default Login;
