import { Header, Footer, PopupConnexion, PopupCommande, HeroSlider } from "../";
import { useSelector, useDispatch } from "react-redux";
import { TedErrorPopup } from "../../../../core-ui";
import { closeError } from "../../../../global-state/redux";

const Container = ({ children }) => {
  const { open, number, actionName } = useSelector((store) => store.modal);
  const configError = useSelector((store) => store.error);
  const dispatch = useDispatch();
  return (
    <>
      <main className="site main">
        <Header />
        {/* hero section */}
        <HeroSlider />

        {/* end of hero section */}
        <div className="container-main">{children}</div>
        <Footer />
      </main>

      {open && actionName === "passCommande" && <PopupCommande />}
      {open && actionName === "connected" && <PopupConnexion />}
      <TedErrorPopup
        errorsConfig={configError} //  const errors = { messageError: msg, showError: err, typeError: typeErr,};
        fxClosePopup={() => dispatch(closeError())}
      />
    </>
  );
};

export default Container;
