import React from "react";
import { TedModal, TedButton } from "../../core-ui";
import { useDispatch, useSelector } from "react-redux";
import {
  setOpenModal,
  setLoading,
  setError,
  setIsSaved,
} from "../../global-state/redux";
import { deleteRequest } from "../../network/httpRequest";
import { urls } from "../../constant";
const urlsDelete = [
  urls.user,
  urls.produit + "/categorie",
  urls.produit,
  urls.commande,
  urls.appro,
];

const DeleteContent = () => {
  const dispatch = useDispatch();
  const { isloading } = useSelector((store) => store.loading);
  const {
    open,
    number,
    actionTitle,
    actionDescription,
    data: { id, uuid },
  } = useSelector((store) => store.modal);

  const deleted = async () => {
    dispatch(setLoading());
    const { err, msg, data, typeErr } = await deleteRequest(
      `${urlsDelete[number]}/${id}--${uuid}`,
      "json"
    );
    if (typeErr === "failed") {
      dispatch(setLoading());
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    dispatch(setLoading());
    dispatch(setError({ err, msg, data, typeErr }));
    dispatch(setOpenModal({ actionName: "", number: 0 }));
    return dispatch(setIsSaved());
  };
  return (
    <TedModal
      title={actionTitle}
      isOpen={open}
      fxCloseAndOpen={() => dispatch(setOpenModal({ number: 0 }))}
      size="moyen-01"
    >
      <div className="delete-content">
        <p>
          Vous êtes sur le point de supprimer definitivement du contenu,
          veuillez noter que cette action est irreversible
        </p>
      </div>
      <div className="delete-btn-modal">
        <TedButton
          type="button"
          classe="danger"
          label="SUPPRIMER"
          loading={isloading}
          fxOnClick={() => deleted()}
        />
      </div>
    </TedModal>
  );
};

export default DeleteContent;
