import React, { useEffect, useState } from "react";
import { urls, images, icons } from "../../../../constant";
import { useSelector, useDispatch } from "react-redux";
import {
  setError,
  setLoading,
  setIsSaved,
  setOpenModal,
} from "../../../../global-state/redux";
import { getRequest } from "../../../../network/httpRequest";
import { PaginationItems } from "../../../../components";
const configHeaderTable = [
  "Nom",
  "Prénom",
  "Rôle",
  "Adresse",
  "Nom d'utilisateur",
  "Action",
];

const ListUsers = ({ data }) => {
  const [dataUsers, setDataUsers] = useState({ count: 0, rows: [] });
  const { isSaved } = useSelector((store) => store.loading);
  const dispatch = useDispatch();
  const getUsers = async (page = 1) => {
    dispatch(setLoading());
    const { err, msg, data, typeErr } = await getRequest(
      `${urls.user}?lang=fr&page=${page}`
    );
    dispatch(setLoading());
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    setDataUsers(data);
  };
  useEffect(() => {
    getUsers();
  }, [isSaved]);
  return (
    <div>
      <table className="table-normal">
        <thead>
          <tr>
            {configHeaderTable.map((i, idx) => {
              return <td key={idx}>{i}</td>;
            })}
          </tr>
        </thead>
        <tbody>
          {dataUsers.rows &&
            dataUsers.rows.map((item) => {
              const {
                id,
                uuid,
                nom,
                prenom,
                level,
                role,
                province,
                commune,
                zone,
                colline,
                localite,
                auth,
              } = item;
              return (
                <tr key={id}>
                  <td>{nom}</td>
                  <td>{prenom}</td>
                  <td>
                    {role?.description} <br />
                    {role?.id === 5
                      ? level === 1
                        ? "Niveau 1"
                        : "Niveau 2"
                      : ""}
                  </td>
                  <td>{`${province?.name} ${commune ? commune.name : ""}`}</td>
                  <td>
                    <span>{auth[0]["username"]}</span>
                  </td>
                  <td>
                    <div className="actions">
                      <div className="btn success">
                        <span>{icons.edit}</span>
                        {/* <span>Modifier</span> */}
                      </div>

                      <div
                        className="btn danger"
                        onClick={() =>
                          dispatch(
                            setOpenModal({
                              actionName: "deleteContent",
                              data: item,
                              actionTitle: `suppression d'utilisateur ${nom} ${prenom}`,
                              number: 0,
                            })
                          )
                        }
                      >
                        <span>{icons.deleted}</span>
                        {/* <span> Supprimer</span> */}
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <PaginationItems
        itemsPerPage={10}
        dataLength={dataUsers.count}
        changePage={getUsers}
      />
    </div>
  );
};

export default ListUsers;
