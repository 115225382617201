import { useEffect } from "react";
import { Container, Categorie, ListArticles } from "../../component";
import { useGetData } from "../../../../hooks";
import { urls } from "../../../../constant";
import { useDispatch, useSelector } from "react-redux";

const Home = () => {
  const { currentMenu } = useSelector((store) => store.data);
  const [loading, data, getData] = useGetData(
    `${urls.produit}/web-categorie?lang=fr&type=${currentMenu}`
  );
  useEffect(() => {
    getData();
  }, [currentMenu]);
  return (
    <Container>
      <Categorie data={data} loading={loading} />
      <ListArticles data={data} isloading={loading} />
    </Container>
  );
};

export default Home;
