const setDataToUpdate = (dispatch, dataToSet) => {
  for (const key in dataToSet) {
    dispatch({
      type: "SET_FORM_INPUT_VALUE",
      payload: {
        name: key,
        value: dataToSet[key],
      },
    });
  }
};
const setEmptyForm = (dispatch, dataToSet) => {
  for (const key in dataToSet) {
    dispatch({
      type: "SET_FORM_INPUT_VALUE",
      payload: {
        name: key,
        value: "",
      },
    });
  }
};

export default { setDataToUpdate, setEmptyForm };
