import React from "react";
import "./Lang.css";
import i18n from "../../translations/i18n";
import { useSelector, useDispatch } from "react-redux";
import { setLanguage } from "../../global-state/redux";
const langues = [
  {
    id: "fr",
    description: "FR",
  },
  {
    id: "en",
    description: "EN",
  },
  {
    id: "kr",
    description: "KR",
  },
];

const LangForm = ({ fxChoosedLang, defaultLang }) => {
  const dispatch = useDispatch();
  const changeLangue = (lang) => {
    fxChoosedLang(lang);
  };
  return (
    <div className="langue">
      {langues.map((item, idx) => {
        return (
          <ItemLang
            {...item}
            key={idx}
            fxChange={changeLangue}
            defaultLang={defaultLang}
          />
        );
      })}
    </div>
  );
};

const ItemLang = ({ id, description, fxChange, defaultLang }) => {
  const deflang = defaultLang;
  return (
    <div
      onClick={() => fxChange(id)}
      className={`langue-item ${id === deflang ? "active" : "no-active"}`}
    >
      {description}
    </div>
  );
};
export default LangForm;
