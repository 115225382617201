import React from "react";
import { images } from "../../../../constant";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-center">
        <div className="logo">
          <figure>
            <img src={images.logo_seed} alt="logo site" />
          </figure>
        </div>
        <div className="contact">
          <div>
            <figure>
              <img src={images.phone} alt="phone number" />
            </figure>
          </div>
          <div className="telephone">
            <span>+257 22 28 11 09</span>
            <span>+257 79 99 00 61</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
