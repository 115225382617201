import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError, setLoading } from "../../global-state/redux";
import "./Vendeurs.css";
import { data, urls, menu } from "../../constant";
import { getRequest } from "../../network/httpRequest";
import { Container, MenuPager, HeaderPager } from "../../components";
import { ListVendeurs } from "./small";
import { PopupListArticleVendeur } from "./small";
const tabs = menu.tabsVendeurs;
const Vendeurs = () => {
  const [tabIndex, setTabIndex] = useState(tabs[0]);
  const { open, number, actionName } = useSelector((store) => store.modal);
  const tabChanging = () => {};
  return (
    <>
      <Container pageName={"vendeurs"}>
        {/* <HeaderPager title={tabIndex.description} /> */}
        <MenuPager tabs={tabs} fxSelectedtab={tabChanging} />
        <ListVendeurs />
      </Container>
      {open && actionName === "addQuantity" && <PopupListArticleVendeur />}
    </>
  );
};

export default Vendeurs;
