import React, { useEffect, useState, useReducer, useRef } from "react";
import { urls, images, icons } from "../../../constant";
import { useSelector, useDispatch } from "react-redux";
import {
  setError,
  setLoading,
  setIsSaved,
  setOpenModal,
  setData,
  setDropValue,
} from "../../../global-state/redux";
import { getRequest, postRequest } from "../../../network/httpRequest";
import { formatterDateAndHour } from "../../../helpers";
import {
  TedEmpty,
  TedDropdown,
  TedCheckBox,
  TedButton,
} from "../../../core-ui";
import { Formulaire } from "../../../components";
import {
  configFormFiltreRapportDeposit,
  defaultValueFormFiltreRapportDeposit,
  reducer,
} from "../../../reducers";
import { useDownloadExcel } from "react-export-table-to-excel";
const configHeaderTable = [
  "Date",
  "UMVA ID",
  "Nom & Prénom",
  "UMVA Card",
  "Uban",
  "Type",
  "Montant",
];

const RapportDeposit = ({ data }) => {
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `RAPPORT DEPOSIT UMVA Market DU ${new Date()}`,
    sheet: "DEPOSIT",
  });

  const [dataDeposit, setDataDeposit] = useState([]);
  const {
    dataUser: {
      user: {
        auth: { roleId },
      },
    },
  } = useSelector((store) => store.user);
  const { isSaved } = useSelector((store) => store.loading);
  //   const { data: commandes } = useSelector((store) => store.data);
  const dispatch = useDispatch();
  const getDeposit = async (dataForm) => {
    dispatch(setLoading());
    const datas = dataForm;
    const { err, msg, data, typeErr } = await postRequest(
      `${urls.rapport}/deposit`,
      "json",
      datas
    );
    dispatch(setLoading());
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    setDataDeposit(data);
  };
  useEffect(() => {
    // getDeposit();
  }, [isSaved]);

  return (
    <div>
      <div>
        <FiltreRapportDeposit
          data={data}
          fxFilter={getDeposit}
          fxDownloadXls={onDownload}
          tableData={dataDeposit}
        />
      </div>
      <table className="table-normal">
        <thead>
          <tr>
            {configHeaderTable.map((i, idx) => {
              return <td key={idx}>{i}</td>;
            })}
          </tr>
        </thead>
        <tbody>
          {dataDeposit &&
            dataDeposit.map((item) => {
              const {
                id,
                umva_id,
                first_name,
                last_name,
                umva_cardid,
                uban,
                has_umva,
                amount,
                status,
                date,
              } = item;
              return (
                <tr key={id}>
                  <td>{date}</td>
                  <td>{umva_id ? umva_id : "-"}</td>
                  <td>{`${first_name} ${last_name}`}</td>
                  <td>{umva_cardid}</td>
                  <td>{uban}</td>
                  <td>{has_umva === 0 ? "Nouveau" : "Ancien"}</td>
                  <td>{amount}</td>
                </tr>
              );
            })}
        </tbody>
      </table>

      {dataDeposit.length < 1 && (
        <TedEmpty description={"Aucun deposit trouvé"} icon={icons.empty} />
      )}

      {/* excel to download */}
      <div style={{ display: "none" }}>
        <table ref={tableRef}>
          <tbody>
            <tr>
              <th>Date</th>
              <th>UMVA ID</th>
              <th>Nom</th>
              <th>Prenom</th>
              <th>UMVA CARD</th>
              <th>UBAN</th>
              <th>Type</th>
              <th>Montant</th>
            </tr>
            {dataDeposit &&
              dataDeposit.map((item) => {
                const {
                  id,
                  umva_id,
                  first_name,
                  last_name,
                  umva_cardid,
                  uban,
                  has_umva,
                  date,
                  amount,
                } = item;
                return (
                  <tr key={id}>
                    <td>{date}</td>
                    <td>{umva_id ? umva_id : "-"}</td>
                    <td>{first_name}</td>
                    <td>{last_name}</td>
                    <td>{umva_cardid}</td>
                    <td>{uban}</td>
                    <td>{has_umva === 0 ? "Nouveau" : "Ancien"}</td>
                    <td>{amount}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const FiltreRapportDeposit = ({ data, fxFilter, fxDownloadXls, tableData }) => {
  const { isloading } = useSelector((store) => store.loading);
  const [configForm, dispatchConfig] = useReducer(
    reducer,
    configFormFiltreRapportDeposit
  );
  const [defaultValue, dispatchDefautlValue] = useReducer(
    reducer,
    defaultValueFormFiltreRapportDeposit
  );
  useEffect(() => {}, []);
  return (
    <div>
      <div>
        <Formulaire
          fields={configForm}
          dispatch={dispatchDefautlValue}
          values={defaultValue}
          align="row"
        />
      </div>
      <div className="save-btn-filtre">
        <div>
          {tableData.length > 0 && (
            <TedButton
              type="button"
              classe="success"
              label="EXPORTER RAPPORT EN EXCEL"
              loading={isloading}
              fxOnClick={() => fxDownloadXls()}
            />
          )}
        </div>
        <div>
          <TedButton
            type="button"
            classe="info"
            label="APPLIQUER FILTRE"
            loading={isloading}
            fxOnClick={() => fxFilter(defaultValue)}
          />
        </div>
      </div>
    </div>
  );
};

export default RapportDeposit;
