import { useState, useEffect } from "react";
import { useGetFxData } from "../../../hooks";
import { urls } from "../../../constant";
import { useSelector, useDispatch } from "react-redux";
import { setOpenModal } from "../../../global-state/redux";
const skeltonItems = [{}, {}, {}, {}, {}, {}, {}, {}];
const ListArticles = ({ data, isloading }) => {
  const [dt, setDt] = useState([]);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const { selectedCateg } = useSelector((store) => store.data);
  const [loading, fxData, getFxData] = useGetFxData();

  useEffect(() => {
    setDt(data);
    setLoader(isloading);
  }, [data, isloading]);

  useEffect(() => {
    if (selectedCateg > 0) {
      setLoader(loading);
      getFxData(`${urls.produit}/web-categorie?lang=fr&categ=${selectedCateg}`);
    }
  }, [selectedCateg]);

  useEffect(() => {
    if (Object.keys(fxData).length > 0) {
      setDt(fxData);
      setLoader(loading);
    }
  }, [fxData, loading]);

  return (
    <div className="articles">
      <div className="articles-center">
        <h2 className="title">
          <span>nos</span> articles
        </h2>
        <div className="list-articles">
          {!loader && Object.keys(dt).length > 0 && dt?.articles.length > 0 && (
            <>
              {dt?.articles.map((item, idx) => {
                return (
                  <ArticleItem
                    item={item}
                    key={idx}
                    fxOnClick={() =>
                      dispatch(
                        setOpenModal({ actionName: "passCommande", data: item })
                      )
                    }
                  />
                );
              })}
            </>
          )}
          {!loader && Object.keys(dt).length > 0 && dt?.articles.length < 1 && (
            <>
              <p className="empty">aucun article disponible</p>
            </>
          )}
          {loader && (
            <>
              {skeltonItems.map((i, idx) => {
                return <ArticleItemSkeleton key={idx} />;
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const ArticleItem = ({ item, fxOnClick }) => {
  const { path_image_web, translation, prix, list_produit } = item;
  return (
    <div className="article-item">
      <div className="article-image">
        <img
          src={process.env.REACT_APP_URL_PUBLIC_FOLDER + path_image_web}
          alt={translation[0]?.description}
        />
      </div>
      <div className="description-article">
        <span>{translation[0]?.name}</span>
        <span>categorie : {list_produit?.translation[0]?.description}</span>
        <span>bif {prix}</span>
      </div>

      <div className="article-overlay">
        <button onClick={fxOnClick}>commander</button>
      </div>
    </div>
  );
};

const ArticleItemSkeleton = () => {
  return (
    <div className="article-item skeleton">
      <div className="article-image">
        <img />
      </div>
      <div className="description-article">
        <span></span>
        <span></span>
      </div>

      <div className="article-overlay">
        <button></button>
      </div>
    </div>
  );
};

export default ListArticles;
