import React, { useEffect, useState } from "react";
import "./MenuPager.css";

const MenuPager = ({ tabs, fxSelectedtab, defaultTab }) => {
  const [value, setValue] = useState(!defaultTab ? 0 : defaultTab);
  const onClickTab = (data) => {
    setValue(data.id);
    fxSelectedtab(data);
  };

  return (
    <div className="menu-pager">
      <div>
        <ul>
          {tabs.map((tab, idx) => {
            return (
              <MenuItem
                {...tab}
                value={value}
                changeValue={() => onClickTab(tab)}
                key={idx}
              />
            );
          })}
        </ul>
      </div>
      <div></div>
    </div>
  );
};

const MenuItem = ({ name, id, value, changeValue }) => {
  useEffect(() => {}, [name]);
  return (
    <li onClick={changeValue} className={value === id ? "active" : "no-active"}>
      <p>{name}</p>
    </li>
  );
};
export default MenuPager;
