import React, { useState, useReducer, useEffect } from "react";
import { TedSideModal, TedButton } from "../../../core-ui";
import { useSelector, useDispatch } from "react-redux";
import {
  setOpenModal,
  setLoading,
  setIsSaved,
} from "../../../global-state/redux";
import { icons, urls } from "../../../constant";
import { Formulaire } from "../../../components";
import {
  reducer,
  configFormAddApprovisionnement,
  defaultValueFormAddApprovisionnement,
} from "../../../reducers";
import { setError } from "../../../global-state/redux";
import { postRequest } from "../../../network/httpRequest";

const configHeaderTable = ["Article", "Qte", "Action"];

const PopupListArticleVendeur = () => {
  const dispatch = useDispatch();
  const {
    dataUser: {
      user: {
        auth: { roleId },
      },
    },
  } = useSelector((store) => store.user);
  const { open, number, data } = useSelector((store) => store.modal);
  const { produits_vendeurs, nom, prenom } = data;
  const [hiddenForm, setHiddenForm] = useState({
    titCustom: `QTE DISPONIBLE AU VENDEUR ${nom} ${prenom}`,
    isHidden: false,
  });
  const fxHiddenForm = () => {
    setHiddenForm({
      titCustom: !hiddenForm.isHidden
        ? `APPROVISIOONEMENT AU VENDEUR ${nom} ${prenom}`
        : `QTE DISPONIBLE AU VENDEUR ${nom} ${prenom}`,
      isHidden: !hiddenForm.isHidden,
    });
  };

  return (
    <TedSideModal
      title={hiddenForm.titCustom}
      isOpen={open}
      fxCloseAndOpen={() => dispatch(setOpenModal({ number: 0 }))}
      size="moyen-01"
    >
      {hiddenForm.isHidden ? (
        <FormAddApprovisionnement />
      ) : (
        <div className="table-display">
          <table className="table-normal">
            <thead>
              <tr>
                {configHeaderTable.map((i, idx) => {
                  return <td key={idx}>{i}</td>;
                })}
              </tr>
            </thead>
            <tbody>
              {produits_vendeurs &&
                produits_vendeurs.map((item) => {
                  const { id, qte, produit_detail } = item;
                  return (
                    <tr key={id}>
                      <td>
                        {produit_detail && produit_detail.translation[0]?.name}
                      </td>
                      <td>{qte}</td>
                      <td>
                        <div className="actions">
                          <div className="btn success">
                            <span>{icons.add}</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}

      {/* button add approvisionnement */}
      {roleId === 4 && (
        <div className="float-container" onClick={() => fxHiddenForm()}>
          <div className="btn success">
            <span>{!hiddenForm.isHidden ? icons.add : icons.close_white}</span>
          </div>
        </div>
      )}
    </TedSideModal>
  );
};

const FormAddApprovisionnement = () => {
  const dispatch = useDispatch();
  const { isloading } = useSelector((store) => store.loading);
  const { data_all } = useSelector((store) => store.data);
  const { data: modalData } = useSelector((store) => store.modal);
  const [configForm, dispatchConfig] = useReducer(
    reducer,
    configFormAddApprovisionnement
  );
  const [defaultValue, dispatchDefautlValue] = useReducer(
    reducer,
    defaultValueFormAddApprovisionnement
  );

  const [pannierItem, setPannierItem] = useState([]);

  useEffect(() => {
    dispatchConfig({
      type: "SET_SELECT_OPTION_DATA_CATEGORIE_ARTICLE",
      payload: data_all.categories,
    });
  }, []);
  useEffect(() => {
    dispatchConfig({
      type: "SET_DEPENDANT_ARTICLE_FOR_SELECTED_CATEGORIE",
      payload: { categId: defaultValue.categId, articles: data_all.articles },
    });
  }, [defaultValue.categId]);

  const addToPannier = () => {
    const item = {
      qte: defaultValue.qte,
      produitId: defaultValue.produitId,
    };

    if (!item.produitId || item.qte < 1) {
      return dispatch(
        setError({
          err: true,
          msg: !item.produitId
            ? "Vous devez selectionner un article"
            : "La quantité doit être superieure à 0",
          data: [],
          typeErr: "failed",
        })
      );
    }
    //search for article name
    // console.log(data_all.articles);
    const artDetails = data_all.articles.filter(
      (item) => item.id === defaultValue.produitId
    );
    item.produitName = artDetails && artDetails[0].translation[0]?.name;

    //search if items exist
    const itemExist = pannierItem.filter(
      (item) => item.produitId === defaultValue.produitId
    );
    if (itemExist.length > 0) {
      // item.qte = parseFloat(item.qte) + parseFloat(defaultValue.qte);
      const newPannier = pannierItem.map((item) => {
        if (item.produitId === itemExist[0].produitId) {
          return {
            ...item,
            qte: parseFloat(item.qte) + parseFloat(defaultValue.qte),
          };
        }
        return item;
      });
      setPannierItem(newPannier);
    } else {
      setPannierItem([...pannierItem, item]);
    }
    defaultValue.qte = 0;
  };
  const removeToPannier = (produitId) => {
    const newPannier = pannierItem.filter(
      (item) => item.produitId !== produitId
    );
    setPannierItem(newPannier);
  };

  const saveAppro = async () => {
    const datas = {
      vendeurId: modalData.id,
      articles: pannierItem,
    };
    dispatch(setLoading());
    const { err, msg, data, typeErr } = await postRequest(
      `${urls.appro}`,
      "json",
      datas
    );

    if (typeErr === "failed") {
      dispatch(setLoading());
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    dispatch(setLoading());
    dispatch(setError({ err, msg, data, typeErr }));
    dispatch(setOpenModal({ actionName: "" }));
    return dispatch(setIsSaved());
  };
  return (
    <>
      <div className="pannier">
        <div className="first-bloc-pannier">
          <Formulaire
            fields={configForm}
            dispatch={dispatchDefautlValue}
            values={defaultValue}
            align="column"
          />
          <div className="saved">
            <TedButton
              type="button"
              classe="primary"
              label="AJOUTER AU PANNIER"
              loading={false}
              fxOnClick={() => addToPannier()}
            />
          </div>
        </div>
        <div className="second-bloc-pannier"></div>
      </div>
      <div>
        {/* <h4>VOTRE PANNIER</h4> */}
        <table className="table-normal">
          <thead>
            <tr>
              {configHeaderTable.map((i, idx) => {
                return <td key={idx}>{i}</td>;
              })}
            </tr>
          </thead>
          <tbody>
            {pannierItem &&
              pannierItem.map((item, idx) => {
                const { qte, produitId, produitName } = item;
                return (
                  <tr key={idx}>
                    <td>{produitName}</td>
                    <td>{qte}</td>
                    <td>
                      <div className="actions">
                        <div
                          className="btn success"
                          onClick={() => removeToPannier(produitId)}
                        >
                          <span>{icons.close_white}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {pannierItem.length < 1 && (
          <div className="empty-data">
            <span className="danger">Ce pannier est vide !</span>
          </div>
        )}
        {pannierItem.length > 0 && (
          <div className="saved">
            <TedButton
              type="button"
              classe="success"
              label="ENREGISTRER"
              loading={isloading}
              fxOnClick={() => saveAppro()}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default PopupListArticleVendeur;
