import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setError, setIsSaved, setOpenModal } from "../global-state/redux";
import { postRequest } from "../network/httpRequest";
const usePostData = () => {
  const dispatch = useDispatch();
  const [loading, seLoading] = useState(false);
  const postData = async (url, datas) => {
    seLoading(true);
    const requestData = await postRequest(url, "json", datas);
    setTimeout(() => {
      seLoading(false);
    }, 2000);
    const { err, msg, data, typeErr } = requestData;
    if (typeErr === "failed") {
      dispatch(setError({ err, msg, data, typeErr }));
      return false;
    }
    dispatch(setOpenModal({ number: 0 }));
    dispatch(setError({ err, msg, data, typeErr }));
    dispatch(setIsSaved());

    return data ? data : true;
  };

  return [loading, postData];
};
export default usePostData;
