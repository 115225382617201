import icons from "./icons";
//MENU PRINCIPAL
const menu = {
  admin: {
    firstLevel: [
      {
        name: "Dashboard",
        icon: icons.dashboard,
        path: "/dashboard",
        activate: "dashboard",
        id: 1,
      },
      {
        name: "Vendeurs",
        icon: icons.rh,
        path: "/vendeurs",
        activate: "vendeurs",
        id: 5,
      },
      {
        name: "Approvisionnement",
        icon: icons.rh,
        path: "/approvisionnement",
        activate: "approvisionnement",
        id: 5,
      },
      {
        name: "Commandes",
        icon: icons.rh,
        path: "/commandes",
        activate: "commandes",
        id: 5,
      },

      {
        name: "Catégorie & Articles",
        icon: icons.rh,
        path: "/products",
        activate: "products",
        id: 5,
      },
      {
        name: "Deposit & Membres",
        icon: icons.rh,
        path: "/deposit-members",
        activate: "deposit-members",
        id: 6,
      },
      {
        name: "Rapports",
        icon: icons.rapport,
        path: "/rapports",
        activate: "rapports",
        id: 7,
      },
    ],
    secondLevel: [
      {
        name: "Paramètres",
        icon: icons.param,
        path: "/param",
        activate: "param",
        id: 7,
      },
      {
        name: "Deconnexion",
        icon: icons.logout,
        path: "/login",
        id: 8,
        activate: "login",
      },
    ],
  },
  vendeur: {
    firstLevel: [
      {
        name: "Dashboard",
        icon: icons.dashboard,
        path: "/dashboard",
        activate: "dashboard",
        id: 1,
      },
      {
        name: "Mon Stock",
        icon: icons.rh,
        path: "/vendeurs",
        activate: "vendeurs",
        id: 5,
      },
      {
        name: "Mes Approvisionnement",
        icon: icons.rh,
        path: "/approvisionnement",
        activate: "approvisionnement",
        id: 5,
      },
      {
        name: "Mes commandes",
        icon: icons.rh,
        path: "/commandes",
        activate: "commandes",
        id: 5,
      },
    ],
    secondLevel: [
      {
        name: "Mon compte",
        icon: icons.param,
        path: "/param",
        activate: "param",
        id: 6,
      },
      {
        name: "Deconnexion",
        icon: icons.logout,
        path: "/login",
        id: 7,
      },
    ],
  },
  superviseur: {
    firstLevel: [
      {
        name: "Dashboard",
        icon: icons.dashboard,
        path: "/dashboard",
        activate: "dashboard",
        id: 1,
      },
    ],
    secondLevel: [
      {
        name: "Mon compte",
        icon: icons.param,
        path: "/param",
        activate: "param",
        id: 6,
      },
      {
        name: "Deconnexion",
        icon: icons.logout,
        path: "/login",
        id: 7,
      },
    ],
  },
  helpDesk: {
    firstLevel: [
      {
        name: "Dashboard",
        icon: icons.dashboard,
        path: "/dashboard",
        activate: "dashboard",
        id: 1,
      },
      {
        name: "Vendeurs",
        icon: icons.rh,
        path: "/vendeurs",
        activate: "vendeurs",
        id: 5,
      },
      {
        name: "Approvisionnement",
        icon: icons.rh,
        path: "/approvisionnement",
        activate: "approvisionnement",
        id: 5,
      },
    ],
    secondLevel: [
      {
        name: "Mon compte",
        icon: icons.param,
        path: "/param",
        activate: "param",
        id: 6,
      },
      {
        name: "Deconnexion",
        icon: icons.logout,
        path: "/login",
        id: 7,
      },
    ],
  },
  validateur: {
    firstLevel: [
      {
        name: "Dashboard",
        icon: icons.dashboard,
        path: "/dashboard",
        activate: "dashboard",
        id: 1,
      },
      {
        name: "Approvisionnement",
        icon: icons.rh,
        path: "/approvisionnement",
        activate: "approvisionnement",
        id: 5,
      },
    ],
    secondLevel: [
      {
        name: "Mon compte",
        icon: icons.param,
        path: "/param",
        activate: "param",
        id: 6,
      },
      {
        name: "Deconnexion",
        icon: icons.logout,
        path: "/login",
        id: 7,
      },
    ],
  },
  agent: {
    firstLevel: [
      {
        name: "Dashboard",
        icon: icons.dashboard,
        path: "/dashboard",
        activate: "dashboard",
        id: 1,
      },
    ],
    secondLevel: [
      {
        name: "Mon compte",
        icon: icons.param,
        path: "/param",
        activate: "param",
        id: 6,
      },
      {
        name: "Deconnexion",
        icon: icons.logout,
        path: "/login",
        id: 7,
      },
    ],
  },
  client: {},
};

//MENU TABULATIONS

const tabsProduits = [
  {
    id: 0,
    name: "Catégorie",
    description: "Categorie des Articles",
    clause: "",
  },
  {
    id: 1,
    name: "Articles",
    description: "Articles",
    clause: "",
  },
];

const tabsParametre = [
  {
    id: 0,
    name: "Comptes",
    description: "Compte utilisateurs",
    clause: "",
  },
];

const tabsCommandes = [
  {
    id: 0,
    name: "Toutes",
    description: "Toutes les commandes",
    clause: "",
  },
  {
    id: 1,
    name: "En Attente",
    description: "Les commandes en attente",
    clause: "status=1",
  },
  {
    id: 2,
    name: "En Cours",
    description: "Les commandes en cours",
    clause: "status=2",
  },
  {
    id: 3,
    name: "Validée",
    description: "Les commandes validée",
    clause: "status=3",
  },
  {
    id: 4,
    name: "Annulée",
    description: "Les commandes annulée",
    clause: "status=3",
  },
];

const tabsApprovisionnements = [
  {
    id: 0,
    name: "Tous",
    description: "Historique des approvisionnement",
    clause: "",
  },
];

const tabsVendeurs = [
  {
    id: 0,
    name: "Tous",
    description: "Liste de vendeur(s)",
    clause: "",
  },
];
const tabsDepositMembers = [
  {
    id: 0,
    name: "Deposit",
    description: "Liste de tous les deposit",
    clause: "",
  },
  {
    id: 1,
    name: "Nouveaux Membres",
    description: "Liste de tous les nouveaux membres",
    clause: "",
  },
];

const tabsRapports = [
  {
    id: 0,
    name: "Commandes",
    description: "Générer le rapport",
    clause: "",
  },
  {
    id: 1,
    name: "Deposit",
    description: "Générer le rapport",
    clause: "",
  },
];
export default {
  menu,
  tabsProduits,
  tabsParametre,
  tabsCommandes,
  tabsVendeurs,
  tabsApprovisionnements,
  tabsDepositMembers,
  tabsRapports,
};
