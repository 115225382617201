import React, { useEffect, useReducer, useState } from "react";
import { TedModal, TedButton } from "../../../../core-ui";
import { Formulaire, LangForm } from "../../../../components";
import { useSelector, useDispatch } from "react-redux";
import { formHelper } from "../../../../helpers";
import { setOpenModal } from "../../../../global-state/redux";
import {
  reducer,
  configFormArticle,
  defaultValueFormArticle,
  configFormArticleNewLangue,
} from "../../../../reducers";
import { usePostData, usePatchData } from "../../../../hooks";
import { urls } from "../../../../constant";
const title = {
  updateData: "Modifier un article",
  createData: "Ajouter un article",
  addInLanguage: "Ajouter l'article dans une nouvelle langue",
};

const CreateArticle = ({ categorieList }) => {
  const [loading, postData] = usePostData();
  const [loadingUpdate, patchData] = usePatchData();
  const {
    open,
    data: dataModal,
    actionName,
  } = useSelector((store) => store.modal);
  const { lang: deflang } = useSelector((store) => store.data);
  const [formLang, setFormLang] = useState(deflang);
  const dispatch = useDispatch();
  const [configForm, dispatchConfig] = useReducer(reducer, configFormArticle);
  const [defaultValue, dispatchDefautlValue] = useReducer(
    reducer,
    defaultValueFormArticle
  );
  const [configFormOtherLang, dispatchConfigOtherLang] = useReducer(
    reducer,
    configFormArticleNewLangue
  );

  const saveArticles = async () => {
    const formData = defaultValue;
    const datas = new FormData();
    datas.append("name", formData.name);
    datas.append("description", formData.description);
    datas.append("image", formData.image);
    datas.append("categId", formData.categId);
    datas.append("prix", formData.prix);
    datas.append("produitId", 0);
    datas.append("lang", "fr");
    const data = await postData(`${urls.produit}`, datas);
    if (data) {
    }
  };
  const updateArticle = async () => {
    const formData = defaultValue;
    const datas = new FormData();
    datas.append("name", formData.name);
    datas.append("description", formData.description);
    datas.append("image", formData.image);
    datas.append("categId", formData.categId);
    datas.append("prix", formData.prix);
    const data = await patchData(
      `${urls.produit}/${defaultValue.id}?lang=${deflang}`,
      datas
    );
    if (data) {
    }
  };
  const saveInOtherLangue = async () => {
    const formData = defaultValue;
    const datas = new FormData();
    datas.append("description", formData.description);
    datas.append("name", formData.name);
    datas.append("produitId", dataModal.id);
    datas.append("categId", dataModal.categId);
    datas.append("prix", dataModal.prix);
    datas.append("lang", formLang);
    const data = await postData(`${urls.produit}`, datas);
    if (data) {
    }
  };

  useEffect(() => {
    dispatchConfig({
      type: "SET_SELECT_OPTION_DATA_CATEGORIE_ARTICLE",
      payload: categorieList,
    });
  }, []);

  useEffect(() => {
    if (actionName === "createData") {
      formHelper.setDataToUpdate(dispatchDefautlValue, defaultValue);
    }
    if (actionName === "updateData") {
      formHelper.setDataToUpdate(dispatchDefautlValue, dataModal);
    }
  }, []);

  return (
    <TedModal
      title={`${title[actionName]} ${
        actionName === "addInLanguage" ? `(${dataModal.name})` : ""
      }`}
      isOpen={open}
      fxCloseAndOpen={() => dispatch(setOpenModal({ number: 0 }))}
      size="moyen-01"
    >
      <div>
        {actionName === "addInLanguage" && (
          <LangForm
            fxChoosedLang={(lang) => {
              setFormLang(lang);
            }}
            defaultLang={formLang}
          />
        )}
        <Formulaire
          fields={
            actionName === "addInLanguage" ? configFormOtherLang : configForm
          }
          dispatch={dispatchDefautlValue}
          values={defaultValue}
          align="column"
        />
      </div>
      <div className="save-btn-modal">
        <TedButton
          type="button"
          classe="primary"
          label={
            actionName === "createData" || actionName === "addInLanguage"
              ? "ENREGISTRER"
              : "MODIFIER"
          }
          loading={
            actionName === "createData" || actionName === "addInLanguage"
              ? loading
              : loadingUpdate
          }
          fxOnClick={() =>
            actionName === "createData"
              ? saveArticles()
              : actionName === "addInLanguage"
              ? saveInOtherLangue()
              : updateArticle()
          }
        />
      </div>
    </TedModal>
  );
};

export default CreateArticle;
