import React from "react";
import "./Container.css";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
//Depends components
import { Header, NavBar, Footer } from "../";
import { TedErrorPopup } from "../../../core-ui/";
import { DeleteContent, ActionContent } from "../../../reusables";
//redux
import { useSelector } from "react-redux";
//constant
import { closeError } from "../../../global-state/redux";

const Container = ({ pageName, children }) => {
  const configError = useSelector((store) => store.error);
  const { open, data, number, actionName } = useSelector(
    (store) => store.modal
  );
  const dispatch = useDispatch();

  return (
    <>
      <div className="container-app">
        <div className="navigation">
          <NavBar />
        </div>
        <div className="content-main">
          <Header pageName={pageName} />
          <main className="main-container-app">
            <div>{children}</div>
          </main>
          <Footer />
        </div>
      </div>
      <TedErrorPopup
        errorsConfig={configError} //  const errors = { messageError: msg, showError: err, typeError: typeErr,};
        fxClosePopup={() => dispatch(closeError())}
      />
      {open && actionName === "deleteContent" && <DeleteContent indexUrl={0} />}
      {open && actionName === "actionContent" && <ActionContent />}
    </>
  );
};

Container.propTypes = {
  pageName: PropTypes.any.isRequired,
};
export default Container;
