import { icons } from "../constant";
const configFormLogin = [
  {
    name: "username",
    type: "text",
    value: "",
    icon: icons.user,
    label: "Numero de telephone",
  },
  {
    name: "password",
    type: "password",
    value: "",
    icon: icons.password,
    label: "Mot de passe",
  },
];

const configFormCategorieProduit = [
  {
    name: "typeId",
    type: "select",
    icon: "",
    label: "Type",
    options: [
      { id: 1, description: "SEMENCES" },
      { id: 2, description: "PRODUITS" },
      { id: 3, description: "AUTRES" },
    ],
  },
  {
    name: "description",
    type: "text",
    icon: "",
    label: "Déscription",
  },
  {
    name: "image",
    type: "file",
    accept: "image/png,image/gif,image/jpeg,image/jpg",
    icon: {
      nodata: icons.download_file,
      data: icons.download_file_true,
    },
    label: {
      nodata: "Selectionner un fichier cover",
      data: "1 Fichier selectionné",
    },
  },
];
const configFormCategorieProduitNewLangue = [
  {
    name: "description",
    type: "text",
    value: "",
    icon: "",
    label: "Déscription",
  },
];

const configFormArticle = [
  {
    name: "categId",
    type: "select",
    value: "",
    icon: "",
    label: "Catégorie",
    options: [],
  },
  {
    name: "name",
    type: "text",
    value: "",
    icon: "",
    label: "Nom article",
  },
  {
    name: "prix",
    type: "text",
    value: "",
    icon: "",
    label: "Prix (Fbu)",
  },
  {
    name: "description",
    type: "textarea",
    value: "",
    icon: "",
    label: "Description article",
  },

  {
    name: "image",
    type: "file",
    value: "",
    accept: "image/png,image/gif,image/jpeg,image/jpg",
    icon: {
      nodata: icons.download_file,
      data: icons.download_file_true,
    },
    label: {
      nodata: "Selectionner un fichier cover",
      data: "1 Fichier selectionné",
    },
  },
];
const configFormArticleNewLangue = [
  {
    name: "name",
    type: "text",
    value: "",
    icon: "",
    label: "Nom article",
  },
  {
    name: "description",
    type: "textarea",
    value: "",
    icon: "",
    label: "Description article",
  },
];
const configFormCreateUser = {
  firstOne: [
    {
      name: "nom",
      type: "text",
      value: "",
      icon: "",
      label: "Nom",
    },
    {
      name: "prenom",
      type: "text",
      value: "",
      icon: "",
      label: "Prénom",
    },
    {
      name: "roleId",
      type: "select",
      value: "",
      icon: "",
      label: "Rôle",
      options: [],
    },
  ],

  secondOne: [
    {
      name: "level",
      type: "select",
      value: "",
      icon: "",
      label: "Niveau",
      options: [
        {
          id: 1,
          description: "Niveau 1",
        },
        {
          id: 2,
          description: "Niveau 2",
        },
      ],
    },
  ],

  thirdOne: [
    {
      name: "zoneId",
      type: "select",
      value: "",
      icon: "",
      label: "Zône",
      options: [],
    },
    {
      name: "collineId",
      type: "select",
      value: "",
      icon: "",
      label: "Colline",
      options: [],
    },
    {
      name: "localiteId",
      type: "select",
      value: "",
      icon: "",
      label: "Localité",
      options: [],
    },
    {
      name: "username",
      type: "text",
      value: "",
      icon: "",
      label: "Identifiant de connexion",
    },
    {
      name: "password",
      type: "password",
      value: "",
      icon: "",
      label: "Mot de passe",
    },
  ],
  fiveOne: [
    {
      name: "provinceId",
      type: "select",
      value: "",
      icon: "",
      label: "Province",
      options: [],
    },
    {
      name: "communeId",
      type: "select",
      value: "",
      icon: "",
      label: "Commune",
      options: [],
    },
  ],
};
const configFormAddApprovisionnement = [
  {
    name: "categId",
    type: "select",
    value: "",
    icon: "",
    label: "Catégorie",
    options: [],
  },
  {
    name: "produitId",
    type: "select",
    value: "",
    icon: "",
    label: "Article",
    options: [],
  },
  {
    name: "qte",
    type: "number",
    value: 0,
    icon: "",
    label: "Quantité",
  },
];

const configFormAnnulerApprovisionnement = [
  {
    name: "commentaire",
    type: "textarea",
    value: "",
    icon: "",
    label: "Commentaire",
  },
];
const configFormImportFile = [
  {
    name: "file",
    type: "file",
    value: "",
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    icon: {
      nodata: icons.download_file,
      data: icons.download_file_true,
    },
    label: {
      nodata: "Selectionner le fichier Excel",
      data: "1 Fichier selectionné",
    },
  },
];

const configFormAffecterCommande = [
  {
    name: "vendeurId",
    type: "select",
    value: "",
    icon: "",
    label: "Vendeur",
    options: [],
  },
];

const configFormFiltreRapportCommande = {
  firstOne: [
    {
      name: "province",
      type: "select",
      value: "",
      icon: "",
      label: "Province",
      options: [],
    },
    {
      name: "commune",
      type: "select",
      value: "",
      icon: "",
      label: "Commune",
      options: [],
    },
    {
      name: "zone",
      type: "select",
      value: "",
      icon: "",
      label: "Zône",
      options: [],
    },
    {
      name: "typeId",
      type: "select",
      value: "",
      icon: "",
      label: "Type",
      options: [],
    },
    {
      name: "categId",
      type: "select",
      value: "",
      icon: "",
      label: "Catégorie",
      options: [],
    },
  ],

  secondOne: [
    {
      name: "produitId",
      type: "select",
      value: "",
      icon: "",
      label: "Article",
      options: [],
    },
    {
      name: "statusId",
      type: "select",
      value: "",
      icon: "",
      label: "Status",
      options: [],
    },
    {
      name: "dateDebut",
      type: "date",
      value: "",
      icon: "",
      label: "Daté Début",
    },
    {
      name: "dateFin",
      type: "date",
      value: "",
      icon: "",
      label: "Daté Fin",
    },
  ],
};

const configFormFiltreRapportDeposit = [
  {
    name: "has_umva",
    type: "select",
    value: "",
    icon: "",
    label: "Type Membre",
    options: [
      {
        id: 2,
        description: "Ancien",
      },
      {
        id: 1,
        description: "Nouveau",
      },
    ],
  },
  {
    name: "dateDebut",
    type: "date",
    value: "",
    icon: "",
    label: "Daté Début",
  },
  {
    name: "dateFin",
    type: "date",
    value: "",
    icon: "",
    label: "Daté Fin",
  },
];

export {
  configFormLogin,
  configFormCategorieProduit,
  configFormCategorieProduitNewLangue,
  configFormArticle,
  configFormArticleNewLangue,
  configFormCreateUser,
  configFormAddApprovisionnement,
  configFormAnnulerApprovisionnement,
  configFormImportFile,
  configFormAffecterCommande,
  configFormFiltreRapportCommande,
  configFormFiltreRapportDeposit,
};
