import React from "react";
import "./TedInput.css";
import TedSelect from "./TedSelect";
import TedMultiSelect from "./TedMultiSelect";
import PropTypes from "prop-types";

const TedInput = ({ value, icon, type, fxOnchange, name, label, options }) => {
  return (
    <div className="ted-input">
      <div className="icons">{icon}</div>
      {type === "select" ? (
        <TedSelect
          options={options}
          name={name}
          onChange={fxOnchange}
          selected={value}
        />
      ) : type === "multiselect" ? (
        <TedMultiSelect
          options={options}
          name={name}
          onChange={fxOnchange}
          value={value}
        />
      ) : type === "textarea" ? (
        <textarea
          id={name}
          name={name}
          onChange={fxOnchange}
          defaultValue={value}
        ></textarea>
      ) : (
        <input
          type={type}
          id={name}
          name={name}
          defaultValue={value}
          onChange={fxOnchange}
          autoComplete="off"
        />
      )}

      <label htmlFor={name}>{label}</label>
    </div>
  );
};

TedInput.propTypes = {
  type: PropTypes.oneOf([
    "text",
    "password",
    "number",
    "date",
    "select",
    "multiselect",
    "textarea",
  ]),
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  fxOnchange: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired,
};

export default TedInput;
