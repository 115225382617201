import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError, setLoading } from "../../global-state/redux";
import "./Rapports.css";
import { data, urls, menu } from "../../constant";
import { getRequest } from "../../network/httpRequest";
//all components

import { Container, HeaderPager, MenuPager } from "../../components";
import { RapportCommandes, RapportDeposit } from "./small";

const tabs = menu.tabsRapports;
const Rapports = () => {
  const [tabIndex, setTabIndex] = useState(tabs[0]);

  const { open, number, actionName } = useSelector((store) => store.modal);
  const tabChanging = (data) => {
    setTabIndex(data);
  };

  const [dataStatique, setDataStatique] = useState({});
  const { isSaved } = useSelector((store) => store.loading);
  const dispatch = useDispatch();
  const getStatiqueData = async () => {
    dispatch(setLoading());
    const { err, msg, data, typeErr } = await getRequest(
      `${urls.statique}/filtre-commande-data?lang=fr`
    );
    dispatch(setLoading());
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }

    setDataStatique(data);
  };
  useEffect(() => {
    getStatiqueData();
  }, [isSaved]);

  return (
    <>
      <Container pageName={"Rapports"}>
        <HeaderPager title={tabIndex.description} btnTitle="Ajouter" />
        <MenuPager tabs={tabs} fxSelectedtab={tabChanging} />
        {tabIndex.id === 0 && <RapportCommandes data={dataStatique} />}
        {tabIndex.id === 1 && <RapportDeposit />}
        {/* {tabIndex.id === 1 && <ListArticles />} */}
      </Container>
      {/* {tabIndex.id === 0 && open && actionName === "createData" && (
        <CreateCategorie />
      )}
      {tabIndex.id === 1 && open && actionName === "createData" && (
        <CreateArticle categorieList={dataCateg} />
      )} */}
    </>
  );
};

export default Rapports;
