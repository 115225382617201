import React from "react";
import "./Lang.css";
import i18n from "../../translations/i18n";
import { useSelector, useDispatch } from "react-redux";
import { setLanguage } from "../../global-state/redux";
const langues = [
  {
    id: "fr",
    description: "FR",
  },
  {
    id: "en",
    description: "EN",
  },
  {
    id: "kr",
    description: "KR",
  },
];

const Lang = () => {
  // const {} = useSelector((store)=>store.data)
  const dispatch = useDispatch();
  const changeLangue = (lang) => {
    i18n.changeLanguage(lang);
    dispatch(setLanguage(lang));
  };
  return (
    <div className="langue">
      {langues.map((item, idx) => {
        return <ItemLang {...item} key={idx} fxChange={changeLangue} />;
      })}
    </div>
  );
};

const ItemLang = ({ id, description, fxChange }) => {
  // const deflang = i18n.language;
  const { lang: deflang } = useSelector((store) => store.data);
  return (
    <div
      onClick={() => fxChange(id)}
      className={`langue-item ${id === deflang ? "active" : "no-active"}`}
    >
      {description}
    </div>
  );
};
export default Lang;
