import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showError: false,
  messageError: "",
  typeError: "", //success and failed
};
const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError: (state, { payload }) => {
      state.showError = payload.err;
      state.messageError = payload.msg;
      state.typeError = payload.typeErr;
    },
    closeError: (state) => {
      state.showError = false;
    },
  },
});
export const { setError, closeError } = errorSlice.actions;
export default errorSlice.reducer;
