import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError, setLoading } from "../../global-state/redux";
import "./Parametre.css";
import { data, urls, menu } from "../../constant";
import { getRequest } from "../../network/httpRequest";
//all components
import { Container, HeaderPager, MenuPager } from "../../components";
import { CreateUsers, ListUsers } from "./small";
const tabs = menu.tabsParametre;
const Parametre = () => {
  const dispatch = useDispatch();
  const { open, number, actionName } = useSelector((store) => store.modal);
  const [tabIndex, setTabIndex] = useState(tabs[0]);
  const [statique, setStatique] = useState([]);
  const tabChanging = (data) => {
    setTabIndex(data);
  };

  const getAllStatique = async () => {
    dispatch(setLoading());
    const { err, msg, data, typeErr } = await getRequest(
      `${urls.statique}/all`
    );
    dispatch(setLoading());
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    setStatique(data);
  };
  useEffect(() => {
    getAllStatique();
  }, []);
  return (
    <>
      <Container pageName={"Parametre"}>
        <HeaderPager title={tabIndex.description} btnTitle="Ajouter" showBtn />
        <MenuPager tabs={tabs} fxSelectedtab={tabChanging} />
        {tabIndex.id === 0 && <ListUsers />}
      </Container>
      {tabIndex.id === 0 && open && actionName === "createData" && (
        <CreateUsers statique={statique} />
      )}
    </>
  );
};

export default Parametre;
