const urls = {
  login: "/auth/login",
  dashboard: "/dashboard",
  produit: "/produit",
  statique: "/statique",
  user: "/user",
  commande: "/commande",
  appro: "/approvisionnement",
  deposit: "/deposit",
  rapport: "/rapport",
 
};

export default urls;
