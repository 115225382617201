export const loginImage = require("../assets/images/login.png");
export const avatar = require("../assets/images/default.png");
export const lang = require("../assets/images/lang.png");
export const detail = require("../assets/images/detail.png");
export const editing = require("../assets/images/editing.png");
export const deleted = require("../assets/images/delete.png");
// export const bg_login_v1 = require("../assets/images/bg_log_v1.jpg");

export const logo_seed = require("../assets/images/logoseed.png");
export const cat01 = require("../assets/images/cat01.jpg");
export const cat02 = require("../assets/images/cat02.jpg");
export const art01 = require("../assets/images/art01.jpg");
export const left = require("../assets/images/left.png");
export const right = require("../assets/images/right.png");
export const phone = require("../assets/images/call.png");

export const hero01 = require("../assets/images/hero-01.jpg");
export const hero02 = require("../assets/images/hero-02.jpg");
export const hero03 = require("../assets/images/hero-03.jpg");

export default {
  loginImage,
  avatar,
  lang,
  detail,
  editing,
  deleted,
  // bg_login_v1,
  logo_seed,
  cat01,
  cat02,
  left,
  right,
  art01,
  phone,
  hero01,
  hero02,
  hero03,
};
