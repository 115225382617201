import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError, setLoading, setOpenModal } from "../../global-state/redux";
import "./DepositMembers.css";
import { data, urls, menu } from "../../constant";
import { getRequest } from "../../network/httpRequest";
import { Container, MenuPager, HeaderPager } from "../../components";
import { ListDeposit, ListNewMembers } from "./small";
let tabs = menu.tabsDepositMembers;
const DepositMembers = () => {
  //configuration pour le role
  const {
    dataUser: {
      user: {
        auth: { roleId },
      },
    },
  } = useSelector((store) => store.user);

  const [tabIndex, setTabIndex] = useState(tabs[0]);
  const [vendeurs, setVendeurs] = useState([]);
  const { open, actionName, number } = useSelector((store) => store.modal);
  const { data } = useSelector((store) => store.data);
  const dispatch = useDispatch();
  const tabChanging = (data) => {
    setTabIndex(data);
  };

  const getVendeurs = async () => {
    // dispatch(setLoading());
    // const { err, msg, data, typeErr } = await getRequest(
    //   `${urls.user}?lang=fr&role=2`
    // );
    // dispatch(setLoading());
    // if (err) {
    //   return dispatch(setError({ err, msg, data, typeErr }));
    // }
    // setVendeurs(data);
  };
  useEffect(() => {
    getVendeurs();
  }, [data]);
  return (
    <>
      <Container pageName={"Déposit Membres"}>
        <HeaderPager title={tabIndex.description} btnTitle="" />
        <MenuPager
          tabs={tabs}
          fxSelectedtab={tabChanging}
          defaultTab={roleId === 2 ? 2 : 0}
        />
        {tabIndex.id === 0 && <ListDeposit />}
        {tabIndex.id === 1 && <ListNewMembers />}
      </Container>
    </>
  );
};

export default DepositMembers;
