import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TedModal, TedButton } from "../../../../core-ui";
import { urls } from "../../../../constant";
import { postRequest } from "../../../../network/httpRequest";
import {
  setOpenModal,
  setLoginUser,
  setError,
  setLoading,
} from "../../../../global-state/redux";

const PopupConnexion = () => {
  const dispatch = useDispatch();
  const { isloading } = useSelector((store) => store.loading);
  const [data, setData] = useState({ username: "", password: "" });
  const handleChange = (e) => {
    const name = e.target.name;
    setData({ ...data, [name]: e.target.value });
  };

  const login = async () => {
    dispatch(setLoading());
    const datas = data;
    const {
      err,
      msg,
      data: dt,
      typeErr,
    } = await postRequest(urls.login, "json", datas);
    if (err) {
      dispatch(setError({ err, msg, typeErr }));
      dispatch(setLoading());
      return;
    }
    dispatch(setLoading());
    const { token, user } = dt;
    sessionStorage.setItem("xx_tk", token);
    dispatch(setLoginUser({ dataUser: user, isConnected: true }));

    dispatch(setOpenModal({ actionName: "" }));
  };
  return (
    <TedModal
      title={"connectez-vous"}
      isOpen={true}
      fxCloseAndOpen={() => dispatch(setOpenModal({ number: 1 }))}
      size="moyen"
    >
      <div className="connexion-login">
        <div className="description">
          <span>
            Bénéficiez d'une meilleure expérience de navigation en vous
            connectant ou en créant votre compte!
          </span>
        </div>
        <form autoComplete="off">
          <div className="form">
            <div className="form-input">
              <input
                type="text"
                placeholder="Nom d'utilisateur "
                name="username"
                onChange={handleChange}
              />
            </div>
            <div className="form-input">
              <input
                type="password"
                placeholder="Mot de passe"
                name="password"
                onChange={handleChange}
              />
            </div>
            <div className="forgot-password">
              <div>
                {/* <TedCheckBox name={"passowrd-forgot"} label={"Remember me"} /> */}
              </div>
              <div>
                <a href="#/">Mot de passe oublié ?</a>
              </div>
            </div>
            <div className="btn-connexion">
              <TedButton
                type="button"
                classe="primary"
                label="Se connecter"
                loading={isloading}
                fxOnClick={() => login()}
              />
            </div>
            <div className="forgot-password">
              <div className="sans-connexion">
                {/* <a href="#">continuer sans connexion</a> */}
              </div>
            </div>
          </div>
        </form>
      </div>
    </TedModal>
  );
};

export default PopupConnexion;
