import React, { useEffect, useState } from "react";
import { urls, images, icons } from "../../../constant";
import { useSelector, useDispatch } from "react-redux";
import {
  setError,
  setLoading,
  setIsSaved,
  setOpenModal,
  setDataAll,
} from "../../../global-state/redux";
import { getRequest } from "../../../network/httpRequest";
import { TedEmpty } from "../../../core-ui";
import { PaginationItems } from "../../../components";

const configHeaderTable = ["Nom", "Prénom", "Rôle", "Adresse", "Action"];

const ListVendeurs = ({ data }) => {
  const [dataUsers, setDataUsers] = useState({ count: 0, rows: [] });
  const { isSaved } = useSelector((store) => store.loading);

  const dispatch = useDispatch();
  const getUsers = async (page = 1) => {
    dispatch(setLoading());
    const { err, msg, data, typeErr } = await getRequest(
      `${urls.user}?role=2&lang=fr&page=${page}`
    );
    dispatch(setLoading());
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    setDataUsers(data);
  };

  const getCategorieAndProduct = async () => {
    const { err, msg, data, typeErr } = await getRequest(
      `${urls.produit}/categorie?lang=fr`
    );
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    dispatch(setDataAll(data));
  };
  useEffect(() => {
    getUsers();
    getCategorieAndProduct();
  }, [isSaved]);
  return (
    <>
      <div>
        <table className="table-normal">
          <thead>
            <tr>
              {configHeaderTable.map((i, idx) => {
                return <td key={idx}>{i}</td>;
              })}
            </tr>
          </thead>
          <tbody>
            {dataUsers &&
              dataUsers.rows.map((item) => {
                const {
                  id,
                  nom,
                  prenom,
                  level,
                  role,
                  province,
                  commune,
                  zone,
                  colline,
                  localite,
                } = item;
                return (
                  <tr key={id}>
                    <td>{nom}</td>
                    <td>{prenom}</td>
                    <td>
                      {role?.description} <br />
                    </td>
                    <td>{`${province?.name} ${
                      commune ? commune.name : ""
                    }`}</td>
                    <td>
                      <div className="actions">
                        <div
                          className="btn success"
                          onClick={() =>
                            dispatch(
                              setOpenModal({
                                actionName: "addQuantity",
                                data: item,
                              })
                            )
                          }
                        >
                          <span>{icons.stock}</span>
                          <span>Stock</span>
                        </div>

                        <div
                          className="btn danger"
                          onClick={() =>
                            dispatch(
                              setOpenModal({
                                actionName: "deleteContent",
                                data: item,
                                actionTitle: `suppression du vendeur ${nom} ${prenom}`,
                                number: 0,
                              })
                            )
                          }
                        >
                          <span>{icons.deleted}</span>
                          <span>Supprimer</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {dataUsers.rows.length < 1 && (
          <TedEmpty
            description={"Aucun vendeur disponible"}
            icon={icons.empty}
          />
        )}
        <PaginationItems
          itemsPerPage={10}
          dataLength={dataUsers.count}
          changePage={getUsers}
        />
      </div>
    </>
  );
};

export default ListVendeurs;
