import React, { useReducer, useEffect } from "react";
import { TedModal, TedButton } from "../../../core-ui";
import { Formulaire } from "../../../components";
import { useSelector, useDispatch } from "react-redux";
import {
  setOpenModal,
  setLoading,
  setIsSaved,
  setError,
  setData,
  setDropValue,
} from "../../../global-state/redux";

import {
  reducer,
  configFormAffecterCommande,
  defaultValueFormAffecterCommande,
} from "../../../reducers";

import { postRequest } from "../../../network/httpRequest";
import { urls } from "../../../constant";

const AffecterCommande = ({ vendeurs }) => {
  const { open, number } = useSelector((store) => store.modal);
  const { isloading } = useSelector((store) => store.loading);
  const { data: commandes, dropValue } = useSelector((store) => store.data);
  const dispatch = useDispatch();
  const [configForm, dispatchConfig] = useReducer(
    reducer,
    configFormAffecterCommande
  );
  const [defaultValue, dispatchDefautlValue] = useReducer(
    reducer,
    defaultValueFormAffecterCommande
  );

  const AffecterCommande = async () => {
    dispatch(setLoading());
    let datas = { ...defaultValue, commandes: [...commandes] };
    const { err, msg, data, typeErr } = await postRequest(
      `${urls.commande}/affecter`,
      "json",
      datas
    );

    if (typeErr === "failed") {
      dispatch(setLoading());
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    dispatch(setLoading());
    dispatch(setError({ err, msg, data, typeErr }));
    dispatch(setOpenModal({ number: 0 }));
    dispatch(setData([]));
    dispatch(setDropValue(!dropValue));
    return dispatch(setIsSaved());
  };
  useEffect(() => {
    dispatchConfig({
      type: "SET_USER_INPUT_OPTIONS_VENDEURS_DATA",
      payload: { vendeurs },
    });
  }, []);
  return (
    <TedModal
      title={"affecter les commandes séléctionner à vendeur"}
      isOpen={open}
      fxCloseAndOpen={() => dispatch(setOpenModal({ number: 0 }))}
      size="small"
    >
      <Formulaire
        fields={configForm}
        dispatch={dispatchDefautlValue}
        values={defaultValue}
        align="column"
      />

      <div className="save-btn-modal">
        <TedButton
          type="button"
          classe="primary"
          label="AFFECTER"
          loading={isloading}
          fxOnClick={() => AffecterCommande()}
        />
      </div>
    </TedModal>
  );
};

export default AffecterCommande;
