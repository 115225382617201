const defaultValueFormLogin = {
  username: "",
  password: "",
};
const defaultValueFormCategorieProduit = {
  description: "",
  image: "",
  typeId: "",
};

const defaultValueFormArticle = {
  categId: "",
  name: "",
  description: "",
  prix: "",
  image: "",
};

const defaultValueFormCreateUser = {
  nom: "",
  prenom: "",
  level: 1,
  roleId: "",
  provinceId: "",
  communeId: "",
  zoneId: "",
  collineId: "",
  localiteId: "",
  username: "",
  password: "",
};

const defaultValueFormAddApprovisionnement = {
  categId: "",
  produitId: "",
  qte: 0,
};

const defaultValueFormAnnulerApprovisionnement = {
  commentaire: "",
};

const defaultValueFormImportFile = {
  file: "",
};
const defaultValueFormAffecterCommande = {
  vendeurId: "",
};

const defaultValueFormFiltreRapportCommande = {
  province: "",
  commune: "",
  zone: "",
  typeId: "",
  categId: "",
  produitId: "",
  statusId: "",
  dateDebut: "",
  dateFin: "",
};
const defaultValueFormFiltreRapportDeposit = {
  typeMembreId: "",
  dateDebut: "",
  dateFin: "",
};
export {
  defaultValueFormLogin,
  defaultValueFormCategorieProduit,
  defaultValueFormArticle,
  defaultValueFormCreateUser,
  defaultValueFormAddApprovisionnement,
  defaultValueFormAnnulerApprovisionnement,
  defaultValueFormImportFile,
  defaultValueFormAffecterCommande,
  defaultValueFormFiltreRapportCommande,
  defaultValueFormFiltreRapportDeposit,
};
