import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  dataUser: {},
  isConnected: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoginUser: (state, { payload }) => {
      const { dataUser, isConnected } = payload;
      state.dataUser = dataUser;
      state.isConnected = isConnected;
    },
  },
});
export const { setLoginUser } = userSlice.actions;
export default userSlice.reducer;
