import React, { useEffect, useState } from "react";
import { urls, images, icons } from "../../../constant";
import { useSelector, useDispatch } from "react-redux";
import {
  setError,
  setLoading,
  setIsSaved,
  setOpenModal,
} from "../../../global-state/redux";
import { getRequest } from "../../../network/httpRequest";
import { formatterDateAndHour } from "../../../helpers";
import { TedEmpty, TedDropdown } from "../../../core-ui";
import { PaginationItems, Skelton } from "../../../components";
import { useGetFxData } from "../../../hooks";

const configHeaderTable = [
  "Date",
  "Vendeur",
  "Article",
  "Qte",
  "Status",
  "Fait par",
  "1er validateur",
  "2e validateur",
  "Action",
];

const statusAppro = [
  {
    status: "En Attente",
    color: "warning",
  },
  {
    status: "Valider partiel",
    color: "info",
  },
  {
    status: "Valider",
    color: "success",
  },
  {
    status: "Annuler",
    color: "danger",
  },
];

const ListApprovisionnement = ({ data }) => {
  const { isSaved } = useSelector((store) => store.loading);
  const dispatch = useDispatch();
  const [loading, fxData, getFxData] = useGetFxData();
  const { lang: deflang } = useSelector((store) => store.data);

  const getData = async (page = 1) => {
    await getFxData(`${urls.appro}?page=${page}&lang=${deflang}`);
  };

  const configdropDown = [
    {
      name: "Annuler",
      icons: icons.close_white,
      fx: (item) =>
        dispatch(
          setOpenModal({
            actionName: "actionContent",
            data: item,
            actionTitle: `ANNULER L'APPROVISIONNEMENT DU VENDEUR ${item?.vendeur?.nom} ${item?.vendeur?.prenom}`,
            number: 1,
          })
        ),
    },
    {
      name: "Supprimer",
      icons: icons.action_delete,
      fx: (item) =>
        dispatch(
          setOpenModal({
            actionName: "deleteContent",
            data: item,
            actionTitle: `suppression de l'approvisionnement du vendeur ${item?.vendeur?.nom} ${item?.vendeur?.prenom}`,
            number: 4,
          })
        ),
    },
  ];
  useEffect(() => {
    getData();
  }, [isSaved]);

  return (
    <div>
      <table className="table-normal">
        <thead>
          <tr>
            {configHeaderTable.map((i, idx) => {
              return <td key={idx}>{i}</td>;
            })}
          </tr>
        </thead>
        <tbody>
          {!loading &&
            fxData.rows &&
            fxData.rows.length > 0 &&
            fxData.rows.map((item) => {
              const {
                id,
                vendeur,
                produit,
                createur,
                qte,
                status,
                createdAt,
                appro_quantite_details,
              } = item;

              const validateurProfileFx = (id) => {
                const data = appro_quantite_details.filter(
                  (item) => item.status === id
                );

                if (data.length < 1) return "-";
                return `${data[0]?.validateur.nom} ${data[0]?.validateur.prenom}`;
              };
              return (
                <tr key={id}>
                  <td>{formatterDateAndHour(createdAt)}</td>
                  <td>{`${vendeur?.nom} ${vendeur?.prenom}`}</td>
                  <td>{produit?.translation[0]?.name}</td>
                  <td>{qte}</td>
                  <td>
                    <span className={`status ${statusAppro[status].color}`}>
                      {statusAppro[status].status}
                    </span>
                  </td>
                  <td>{`${createur?.nom} ${createur?.prenom}`}</td>
                  <td>{validateurProfileFx(1)}</td>
                  <td>{validateurProfileFx(2)}</td>

                  <td>
                    <div className="actions">
                      <div
                        className={`btn ${status < 2 ? "success" : "info"}`}
                        title={status < 2 ? "Valider" : "Voir historique"}
                        onClick={() =>
                          dispatch(
                            setOpenModal({
                              actionName: "actionContent",
                              data: item,
                              actionTitle: `${
                                status < 2
                                  ? "VALIDATION"
                                  : "HISTORIQUE DE VALIDATION"
                              } DE L'APPROVISIONNEMENT DU VENDEUR ${
                                vendeur?.nom
                              } ${vendeur?.prenom}`,
                              number: 0,
                            })
                          )
                        }
                      >
                        <span>{status < 2 ? icons.checked : icons.histo}</span>
                        <span>{status < 2 ? "Valider" : "Histori"}</span>
                      </div>
                      <div>
                        <TedDropdown
                          configdropDown={configdropDown}
                          data={item}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {loading && <Skelton n={1} />}
      {!loading && fxData.rows && fxData.rows.length < 1 && (
        <TedEmpty description={"Aucun approvisionnement"} icon={icons.empty} />
      )}
      <PaginationItems
        itemsPerPage={10}
        dataLength={fxData.count}
        changePage={getData}
      />
    </div>
  );
};

export default ListApprovisionnement;
