import React from "react";
import { Route, Switch, Redirect,HashRouter as Router } from "react-router-dom";
//all pages
import {
  Home,
  Login,
  Products,
  Error,
  Dashboard,
  Page,
  Table,
  Parametre,
  Commandes,
  Vendeurs,
  Approvisionnements,
  DepositMembers,
  Rapports,
} from "./pages";
import { useSelector, useDispatch } from "react-redux";
const App = () => {
  const dispatch = useDispatch();
  const { isConnected } = useSelector((store) => store.user);
  return (
    <Router>
      <Route exact path="/">
        <Home />
      </Route>

      <Route exact path="/login">
        <Login />
      </Route>
      <Route
        exact
        path="/dashboard"
        render={() =>
          isConnected ? <Dashboard /> : <Redirect to={"/login"} />
        }
      />
      <Route
        exact
        path="/products"
        render={() => (isConnected ? <Products /> : <Redirect to={"/login"} />)}
      />
      <Route
        exact
        path="/commandes"
        render={() =>
          isConnected ? <Commandes /> : <Redirect to={"/login"} />
        }
      />
      <Route
        exact
        path="/approvisionnement"
        render={() =>
          isConnected ? <Approvisionnements /> : <Redirect to={"/login"} />
        }
      />
      <Route
        exact
        path="/vendeurs"
        render={() => (isConnected ? <Vendeurs /> : <Redirect to={"/login"} />)}
      />
      <Route
        exact
        path="/param"
        render={() =>
          isConnected ? <Parametre /> : <Redirect to={"/login"} />
        }
      />
      <Route
        exact
        path="/deposit-members"
        render={() =>
          isConnected ? <DepositMembers /> : <Redirect to={"/login"} />
        }
      />
      <Route
        exact
        path="/rapports"
        render={() => (isConnected ? <Rapports /> : <Redirect to={"/login"} />)}
      />
      <Route exact path="/page-01" render={() => <Page />} />
      <Route exact path="/table" render={() => <Table />} />

      <Route exact path="*">
        <Error />
      </Route>
    </Router>
  );
};

export default App;
