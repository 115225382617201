import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { images } from "../../../constant";
import { NextArrow, PrevArrow } from "../../../components";
const skeltonItems = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
const fakeData = [
  {
    title: "UMVA Market",
    description: "passer vos commandes en toute simplicité",
    img: images.hero01,
  },
  {
    title: "UMVA Market",
    description: "la rapidité fait de nous une meilleure plateforme",
    img: images.hero02,
  },
  {
    title: "UMVA Market",
    description: "des multiples variétés des produits",
    img: images.hero03,
  },
];
const settings = {
  dots: true,
  infinite: true,
  autoplayspeed: 100,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  nextArrow: <NextArrow props />,
  prevArrow: <PrevArrow props />,
};
const HeroSlider = () => {
  return (
    <div className="hero-section">
      <div className="hero-section-center">
        <Slider {...settings}>
          {fakeData.map((item, idx) => {
            return <HeroItem key={idx} {...item} />;
          })}
        </Slider>
      </div>
    </div>
  );
};

const HeroItem = ({ title, description, img }) => {
  return (
    <div className="hero-section-item">
      <img src={img} alt={description} />
      <div>
        {/* <h1>{title}</h1>
        <span>{description}</span> */}
      </div>
    </div>
  );
};

export default HeroSlider;
