import React from "react";
import "./TedModal.css";
import PropTypes from "prop-types";

const TedModal = ({ children, title, isOpen, fxCloseAndOpen, size }) => {
  return (
    <div className={`modal ${isOpen ? "open" : "close"}`}>
      <div className="backdrop" onClick={fxCloseAndOpen}></div>
      <div className={`container-modal ${size ? size : "big"}`}>
        <div className="modal-header">
          <h2>{title}</h2>
        </div>
        <div className="modal-container">{children}</div>
        {/* <div className="modal-footer">
          <span>FOOTER</span>
        </div> */}
      </div>
    </div>
  );
};
TedModal.protoTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  fxCloseAndOpen: PropTypes.func.isRequired,
};
export default TedModal;
