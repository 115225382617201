import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isloading: false,
  isSaved: false,
  isWaitingData: false,
};

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.isloading = !state.isloading;
    },
    setIsWaitingData: (state) => {
      state.isWaitingData = !state.isWaitingData;
    },
    setIsSaved: (state) => {
      state.isSaved = !state.isSaved;
    },
  },
});
export const { setLoading, setIsSaved, setIsWaitingData } =
  loadingSlice.actions;
export default loadingSlice.reducer;
