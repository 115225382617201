import React, { useEffect, useState } from "react";
import Paginate from "react-paginate";
import "./Pagination.css";
const PaginationItems = ({
  itemsPerPage,
  dataLength,
  children,
  changePage,
}) => {
  const pageCount = Math.ceil(dataLength / itemsPerPage);
  const handlePageClick = (event) => {
    const pageNumber = parseInt(event.selected) + 1;
    changePage(pageNumber);
  };

  return (
    <>
      {children}
      <Paginate
        breakLabel="..."
        nextLabel="Next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={10}
        pageCount={pageCount}
        previousLabel="< Previous"
        renderOnZeroPageCount={null}
        className="pagination"
      />
    </>
  );
};

export default PaginationItems;
