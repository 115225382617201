import React, { useEffect, useState } from "react";
// import { Document, Page } from "react-pdf";
import "./PdfView.css";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

const URL = process.env.REACT_APP_URL_PUBLIC_FOLDER;

const PdfSeeAllPages = (props) => {
  const [numPages, setNumPages] = useState(null);
  const { url, icon, close } = props;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    console.log(URL + url);
  }, []);
  return (
    <div className="container-pdf">
      {/* <div className="page">
        <span>TITRE DU FICHIER</span>
        <span>Nombre de pages : {numPages} Page(s)</span>
      </div> */}
      <div
        className="close-viewer"
        onClick={() => close({ isOpen: false, urlFile: "" })}
      >
        <div>{icon}</div>
      </div>
      <div className="backdrop"></div>
      <div>
        <Document
          file={URL + url}
          options={{ workerSrc: "/pdf.worker.js" }}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </div>
    </div>
  );
};

export default PdfSeeAllPages;
