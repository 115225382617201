import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./Products.css";
import { urls, menu } from "../../constant";
import { useGetFxData } from "../../hooks";
import { Container, HeaderPager, MenuPager } from "../../components";
import {
  CreateCategorie,
  ListCategorie,
  ListArticles,
  CreateArticle,
} from "./small";
const tabs = menu.tabsProduits;
const Products = () => {
  const [tabIndex, setTabIndex] = useState(tabs[0]);
  const { lang: deflang } = useSelector((store) => store.data);
  const { open, actionName } = useSelector((store) => store.modal);
  const [loading, fxData, getFxData] = useGetFxData();
  const tabChanging = (data) => {
    setTabIndex(data);
  };
  const { isSaved } = useSelector((store) => store.loading);
  useEffect(() => {
    getFxData(`${urls.produit}/categorie?lang=${deflang}`);
  }, [isSaved, deflang]);

  return (
    <>
      <Container pageName={"Catégorie & Articles"}>
        <HeaderPager title={tabIndex.description} btnTitle="Ajouter" showBtn />
        <MenuPager tabs={tabs} fxSelectedtab={tabChanging} />
        {tabIndex.id === 0 && (
          <ListCategorie
            data={Object.keys(fxData).length > 0 ? fxData.categories : []}
          />
        )}
        {tabIndex.id === 1 && <ListArticles />}
      </Container>
      {tabIndex.id === 0 &&
        open &&
        (actionName === "createData" ||
          actionName === "updateData" ||
          actionName === "addInLanguage") && <CreateCategorie />}
      {tabIndex.id === 1 &&
        open &&
        (actionName === "createData" ||
          actionName === "updateData" ||
          actionName === "addInLanguage") && (
          <CreateArticle
            categorieList={
              Object.keys(fxData).length > 0 ? fxData.categories : []
            }
          />
        )}
    </>
  );
};

export default Products;
