import React, { useEffect, useReducer, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TedModal, TedButton } from "../../../../core-ui";
import { Formulaire, LangForm } from "../../../../components";
import { setOpenModal } from "../../../../global-state/redux";
import {
  reducer,
  configFormCategorieProduit,
  defaultValueFormCategorieProduit,
  configFormCategorieProduitNewLangue,
} from "../../../../reducers";
import { urls } from "../../../../constant";
import { formHelper } from "../../../../helpers";
import { usePostData, usePatchData } from "../../../../hooks";
const title = {
  updateData: "Modifier la catégorie du produit",
  createData: "Ajouter la catégorie du produit",
  addInLanguage: "Ajouter la catégorie dans une nouvelle langue",
};
const CreateCategorie = () => {
  const {
    open,
    number,
    data: dataModal,
    actionName,
  } = useSelector((store) => store.modal);
  const { lang: deflang } = useSelector((store) => store.data);
  const [formLang, setFormLang] = useState(deflang);
  const [loading, postData] = usePostData();
  const [loadingUpdate, patchData] = usePatchData();
  const dispatch = useDispatch();
  const [configForm, dispatchConfig] = useReducer(
    reducer,
    configFormCategorieProduit
  );
  const [defaultValue, dispatchDefautlValue] = useReducer(
    reducer,
    defaultValueFormCategorieProduit
  );
  const [configFormOtherLang, dispatchConfigOtherLang] = useReducer(
    reducer,
    configFormCategorieProduitNewLangue
  );
  const saveCategorie = async () => {
    const formData = defaultValue;
    const datas = new FormData();
    datas.append("description", formData.description);
    datas.append("image", formData.image);
    datas.append("categId", 0);
    datas.append("lang", "fr");
    datas.append("typeId", formData.typeId);
    const data = await postData(`${urls.produit}/categorie`, datas);
    if (data) {
    }
  };
  const updateCategorie = async () => {
    const formData = defaultValue;
    const datas = new FormData();
    datas.append("description", formData.description);
    datas.append("image", formData.image);
    datas.append("typeId", formData.typeId);
    const data = await patchData(
      `${urls.produit}/categorie/${defaultValue.id}?lang=${deflang}`,
      datas
    );
    if (data) {
    }
  };
  const saveInOtherLangue = async () => {
    const formData = defaultValue;
    const datas = new FormData();
    datas.append("description", formData.description);
    datas.append("categId", dataModal.id);
    datas.append("lang", formLang);
    datas.append("typeId", dataModal.typeId);
    const data = await postData(`${urls.produit}/categorie`, datas);
    if (data) {
    }
  };

  useEffect(() => {
    if (actionName === "createData") {
      formHelper.setDataToUpdate(dispatchDefautlValue, defaultValue);
    }
    if (actionName === "updateData") {
      formHelper.setDataToUpdate(dispatchDefautlValue, dataModal);
    }
  }, []);

  return (
    <TedModal
      title={`${title[actionName]} ${
        actionName === "addInLanguage" ? `(${dataModal.description})` : ""
      }`}
      isOpen={open}
      fxCloseAndOpen={() => dispatch(setOpenModal({ number: 0 }))}
      size="moyen-01"
    >
      <div>
        {actionName === "addInLanguage" && (
          <LangForm
            fxChoosedLang={(lang) => {
              setFormLang(lang);
            }}
            defaultLang={formLang}
          />
        )}
        <Formulaire
          fields={
            actionName === "addInLanguage" ? configFormOtherLang : configForm
          }
          dispatch={dispatchDefautlValue}
          values={defaultValue}
          align="column"
        />
      </div>
      <div className="save-btn-modal">
        <TedButton
          type="button"
          classe="primary"
          label={
            actionName === "createData" || actionName === "addInLanguage"
              ? "ENREGISTRER"
              : "MODIFIER"
          }
          loading={
            actionName === "createData" || actionName === "addInLanguage"
              ? loading
              : loadingUpdate
          }
          fxOnClick={() =>
            actionName === "createData"
              ? saveCategorie()
              : actionName === "addInLanguage"
              ? saveInOtherLangue()
              : updateCategorie()
          }
        />
      </div>
    </TedModal>
  );
};

export default CreateCategorie;
