import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError, setLoading } from "../../global-state/redux";
import "./Dashboard.css";

import { data, urls } from "../../constant";
import { getRequest } from "../../network/httpRequest";
//all components
import { Graphiques } from "./graphiques";
import { Container } from "../../components";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { lang } = useSelector((store) => store.data);
  const [dataD, setDataD] = useState([]);
  const [dataGrap, setDataGraph] = useState([]);
  const getDashboard = async () => {
    dispatch(setLoading());
    const { err, msg, data, typeErr } = await getRequest(`${urls.dashboard}`);
    dispatch(setLoading());
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    setDataD(data);
  };
  const getRapportGraphs = async () => {
    dispatch(setLoading());
    const { err, msg, data, typeErr } = await getRequest(
      `${urls.dashboard}/graphique?lang=fr`
    );
    dispatch(setLoading());
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    setDataGraph(data);
  };
  useEffect(() => {
    getDashboard();
    getRapportGraphs();
  }, [lang]);

  console.log(dataGrap);
  return (
    <>
      <Container pageName={"dashboard"}>
        <div className="dashboard">
          <div className="cards-container">
            {data.DATA_DASHBOARD.map((item, idx) => {
              const newItem = { ...item, number: dataD[item.key] };
              return <CartItem key={idx} {...newItem} />;
            })}
          </div>

          <div className="graphs-list">
            <div className="firs-bloc">
              <div>
                <Graphiques
                  id="graphs01"
                  type="pie"
                  name="Qte"
                  title="Qte disponible par catégorie"
                  data={dataGrap?.dataQteDisponibleParCategorie}
                />
              </div>
              <div>
                <Graphiques
                  id="graphs02"
                  type="pie"
                  name="Qte"
                  title="Qte disponible par variété d'article"
                  data={dataGrap?.dataQteDispoParArticle}
                />
              </div>
            </div>
            <div className="firs-bloc">
              <div>
                <Graphiques
                  id="graphs03"
                  type="column"
                  name="Nombre"
                  title="Nbre de commandes par adresse"
                  yAxisText="Nombre de commades"
                  data={dataGrap?.dataCommandeParAdresse}
                />
              </div>
              <div>
                <Graphiques
                  id="graphs04"
                  type="column"
                  name="Nombre"
                  title="Nbre de commandes par variété d'article"
                  yAxisText="Nombre de commades"
                  data={dataGrap?.dataCommandeParVariete}
                />
              </div>
            </div>
            <div className="firs-bloc">
              <div>
                <Graphiques
                  id="graphs05"
                  type="line"
                  name="Nombre"
                  title="Volume de commandes mensuellement"
                  yAxisText="Nombre de commades"
                  data={dataGrap?.dataVolumeCommandeMensuel}
                />
              </div>
            </div>
            <div className="firs-bloc">
              <div>
                <Graphiques
                  id="graphs06"
                  type="pie"
                  name="Qte"
                  title="Volume de commandes par status"
                  data={dataGrap?.dataCommandeParStatus}
                />
              </div>
              <div>
                <Graphiques
                  id="graphs07"
                  type="pie"
                  name="Qte"
                  title="Commande par catégorie des membres"
                  data={dataGrap?.dataCommandeParCategorieDeMembres}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

const CartItem = ({ number, name }) => {
  return (
    <div className="cards">
      <div>
        <span>{number}</span>
      </div>
      <div>
        <span>{name}</span>
      </div>
    </div>
  );
};

export default Dashboard;
