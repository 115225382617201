import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { images } from "../../../constant";
import { NextArrow, PrevArrow } from "../../../components";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedCateg } from "../../../global-state/redux/data/DataSlice";
const skeltonItems = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 2,
  nextArrow: <NextArrow props />,
  prevArrow: <PrevArrow props />,
};
const Categorie = ({ data, loading }) => {
  return (
    <div className="categorie">
      <div className="categorie-center">
        <h2 className="title">
          <span>nos</span> categories
        </h2>
        <div className="list-categorie">
          {!loading &&
            Object.keys(data).length > 0 &&
            data?.categories.length > 0 && (
              <Slider {...settings}>
                {data?.categories.map((item, idx) => {
                  return <CategorieItem item={item} key={idx} />;
                })}
              </Slider>
            )}
          {!loading &&
            Object.keys(data).length > 0 &&
            data?.categories.length < 1 && (
              <>
                <p className="empty">aucune categorie disponible</p>
              </>
            )}

          {loading && (
            <Slider {...settings}>
              {skeltonItems.map((i, idx) => {
                return <CategorieItemSkeleton key={idx} />;
              })}
            </Slider>
          )}
        </div>
      </div>
    </div>
  );
};

const CategorieItem = ({ item }) => {
  const dispatch = useDispatch();
  const { id, path_image_web, translation } = item;
  return (
    <div
      className="categorie-item"
      onClick={() => dispatch(setSelectedCateg(id))}
    >
      <img
        src={process.env.REACT_APP_URL_PUBLIC_FOLDER + path_image_web}
        alt={translation[0]?.description}
      />
      <div>
        <span>{translation[0]?.description}</span>
      </div>
    </div>
  );
};

const CategorieItemSkeleton = () => {
  return (
    <div className="categorie-item skeleton">
      <img />
      <div>
        <span></span>
      </div>
    </div>
  );
};

export default Categorie;
