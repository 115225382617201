import { icons, data } from "../constant";
import { getRequest } from "../network/httpRequest";

const defaultconfigTableRh = {
  config: {
    champs: [
      {
        attr: "nom",
        img: true,
        action_btn: true,
        css: "f02",
        is_status: false,
        extra_data: { is: false, data: "" },
      },
      {
        attr: "matricule",
        img: false,
        action_btn: true,
        css: "f01",
        is_status: false,
        extra_data: { is: false, data: "" },
      },
      {
        attr: ["grade_detail_id", "description"],
        img: false,
        action_btn: true,
        css: "f01",
        is_status: false,
        extra_data: { is: false, data: "" },
      },
      {
        attr: ["fonction_detail_id", "description"],
        img: false,
        action_btn: true,
        css: "f01",
        is_status: false,
        extra_data: { is: false, data: "" },
      },
      {
        attr: ["categorie_detail_id", "description"],
        img: false,
        action_btn: true,
        css: "f01",
        is_status: false,
        extra_data: { is: false, data: "" },
      },
      {
        attr: ["structure_detail_id", "description"],
        img: false,
        action_btn: true,
        css: "f01",
        is_status: false,
        extra_data: { is: false, data: "" },
      },
      { attr: "", img: false, action_btn: true, css: "f01", is_status: false },
    ],
  },
  header: [
    {
      name: "Nom & prenom",
      css: "f02",
    },
    {
      name: "Matricule",
      css: "f01",
    },
    {
      name: "Grade",
      css: "f01",
    },
    {
      name: "Fonction",
      css: "f01",
    },
    {
      name: "Catégorie",
      css: "f01",
    },
    {
      name: "Structure",
      css: "f01",
    },
    {
      name: "Action",
      css: "f01",
    },
  ],
  empty: { description: "Aucun agent trouvé", icon: icons.failed },
  routeActionBtn: "/rh-agent-details",
  datas: [],
};

// ########################
// ########################
// ######################## sous tables configuration
// ########################
// ########################

const defaultconfigTableCategorieAcademie = {
  config: {
    champs: [
      {
        attr: "description",
        img: false,
        action_btn: false,
        css: "f02",
        is_status: false,
        extra_data: { is: false, data: "" },
      },
      {
        attr: "lang",
        img: false,
        action_btn: true,
        css: "f01",
        is_status: false,
        extra_data: { is: false, data: "" },
      },
      {
        attr: "path_icon_web",
        img: false,
        action_btn: true,
        css: "f01",
        is_status: false,
        extra_data: { is: false, data: "" },
      },

      { attr: "", img: false, action_btn: true, css: "f01", is_status: false },
    ],
  },
  header: [
    {
      name: "categorie",
      css: "f02",
    },
    {
      name: "lang",
      css: "f01",
    },
    {
      name: "icone",
      css: "f01",
    },
  ],
  empty: { description: "Aucun agent trouvé", icon: icons.failed },
  routeActionBtn: "/Detail/Catégorie",
  datas: data.FakeDataCategorie,
};

const configTableCategorieSevice = {
  config: {
    champs: [
      {
        attr: "description",
        img: false,
        action_btn: false,
        css: "f02",
        is_status: false,
        extra_data: { is: false, data: "" },
      },
      {
        attr: "lang",
        img: false,
        action_btn: false,
        css: "f02",
        is_status: false,
        extra_data: { is: false, data: "" },
      },

      { attr: "", img: false, action_btn: true, css: "f01", is_status: false },
    ],
  },
  header: [
    {
      name: "Description",
      css: "f02",
    },
    {
      name: "Lang",
      css: "f02",
    },
  ],
  empty: { description: "Aucun agent trouvé", icon: icons.failed },
  routeActionBtn: "/DetailcategorieService",
  datas: data.FakeDataCategorieService,
};

const defaultconfigTableSevice = {
  config: {
    champs: [
      {
        attr: ["categorie", "description"],
        img: false,
        action_btn: false,
        css: "f02",
        is_status: false,
        extra_data: { is: false, data: "" },
      },
      {
        attr: "Titre",
        img: false,
        action_btn: false,
        css: "f02",
        is_status: false,
        extra_data: { is: false, data: "" },
      },
      {
        attr: "Description",
        img: false,
        action_btn: false,
        css: "f02",
        is_status: false,
        extra_data: { is: false, data: "" },
      },
      {
        attr: "Image",
        img: true,
        action_btn: false,
        css: "f02",
        is_status: false,
        extra_data: { is: false, data: "" },
      },
      {
        attr: "Fournisseur",
        img: false,
        action_btn: false,
        css: "f02",
        is_status: false,
        extra_data: { is: false, data: "" },
      },
      {
        attr: "Video",
        img: false,
        action_btn: false,
        css: "f02",
        is_status: false,
        extra_data: { is: false, data: "" },
      },

      {
        attr: "Provinc",
        img: false,
        action_btn: false,
        css: "f02",
        is_status: false,
        extra_data: { is: false, data: "" },
      },
      {
        attr: "Zone",
        img: false,
        action_btn: false,
        css: "f02",
        is_status: false,
        extra_data: { is: false, data: "" },
      },
      {
        attr: "Coline",
        img: false,
        action_btn: false,
        css: "f02",
        is_status: false,
        extra_data: { is: false, data: "" },
      },
      {
        attr: "SousColine",
        img: false,
        action_btn: false,
        css: "f02",
        is_status: false,
        extra_data: { is: false, data: "" },
      },
      {
        attr: "Telephone",
        img: false,
        action_btn: false,
        css: "f02",
        is_status: false,
        extra_data: { is: false, data: "" },
      },

      { attr: "", img: false, action_btn: true, css: "f01", is_status: false },
    ],
  },
  header: [
    {
      name: "Categorie",
      css: "f02",
    },
    {
      name: "Titre",
      css: "f02",
    },
    {
      name: "Description",
      css: "f02",
    },
    {
      name: "Image",
      css: "f02",
    },
    {
      name: "Fournisseur",
      css: "f02",
    },
    {
      name: "Video",
      css: "f02",
    },
    {
      name: "Province",
      css: "f02",
    },
    {
      name: "Zone",
      css: "f02",
    },
    {
      name: "Coline",
      css: "f02",
    },
    {
      name: "Sous coline",
      css: "f02",
    },
    {
      name: "Telephone",
      css: "f02",
    },
  ],
  empty: { description: "Aucun agent trouvé", icon: icons.failed },
  routeActionBtn: "/DetailVideo/Service",
  datas: data.fakeDataService,
};

const defaultconfigTableVideoAcademie = {
  config: {
    champs: [
      {
        attr: ["catId", "description"],
        img: false,
        action_btn: false,
        css: "f02",
        is_status: false,
        extra_data: { is: false, data: "" },
      },
      {
        attr: "title",
        img: false,
        action_btn: false,
        css: "f02",
        is_status: false,
        extra_data: { is: false, data: "" },
      },
      {
        attr: "path_image_web",
        img: true,
        action_btn: false,
        css: "f02",
        is_status: false,
        extra_data: { is: false, data: "" },
      },
      {
        attr: "path_video_web",
        img: false,
        action_btn: false,
        css: "f02",
        is_status: false,
        extra_data: { is: false, data: "" },
      },

      { attr: "", img: false, action_btn: true, css: "f01", is_status: false },
    ],
  },
  header: [
    {
      name: "Categorie",
      css: "f02",
    },
    {
      name: "Titre",
      css: "f02",
    },
    {
      name: "Image",
      css: "f02",
    },
    {
      name: "Video",
      css: "f02",
    },
  ],
  empty: { description: "Aucun agent trouvé", icon: icons.failed },
  routeActionBtn: "/DetailVideo/Catégorie",
  datas: [],
};

export {
  defaultconfigTableRh,
  defaultconfigTableCategorieAcademie,
  configTableCategorieSevice,
  defaultconfigTableSevice,
  defaultconfigTableVideoAcademie,
};
