import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
const Graphiques = ({ type, title, name, data, id, yAxisText }) => {
  console.log(data);
  const highChartsRender = () => {
    //line, spline, area, areaspline, column, bar, pie, scatter, gauge, arearange, areasplinerange and columnrange
    Highcharts.chart({
      chart: {
        type,
        renderTo: id,
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
      },
      credits: { enabled: false },
      title: {
        floating: false,
        text: title,
        style: {
          fontSize: "20px",
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            format: "{point.name}: {y}",
          },
          innerSize: "70%",
        },
      },
      xAxis: {
        type: "category",
        labels: {
          rotation: -45,
          style: {
            fontSize: "13px",
            fontFamily: "Verdana, sans-serif",
          },
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: yAxisText,
        },
      },

      series: [
        {
          name: name,
          data,
        },
      ],
    });
  };

  useEffect(() => {
    highChartsRender();
  }, [data]);
  return (
    <div>
      <div id={id}></div>
    </div>
  );
};

export default Graphiques;
