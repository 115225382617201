import "./Skelton.css";
import { data } from "../../constant";
const skData = Array(10).fill(1);
const Skelton = ({ n }) => {
  const bodyConfig = [
    data.SK_BODY_COMMANDE,
    data.SK_BODY_APPROV,
    data.SK_BODY_LIST_ARTICLE,
  ];
  return <SkeltonBody bdy={bodyConfig[n]} />;
};

const SkeltonBody = ({ bdy }) => {
  return (
    <table className="table-normal">
      <tbody>
        {skData.map((item, key) => {
          return (
            <tr key={key}>
              {bdy.map(({ tdName, pName }, idx) => {
                return (
                  <td className={tdName} key={idx}>
                    <p className={pName}></p>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Skelton;
