import { createSlice } from "@reduxjs/toolkit";
import { startTransition } from "react";
import i18n from "../../../translations/i18n";

const initialState = {
  lang: i18n.language,
  idDataNewLangue: 0,
  dt: {},
  data_all: {},
  data: [],
  dropValue: false,
  currentMenu: 1,
  selectedCateg: 0,
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setLanguage: (state, { payload }) => {
      state.lang = payload;
    },
    setNewIdLangue: (state, { payload }) => {
      state.idDataNewLangue = payload;
    },
    setCurrentMenu: (state, { payload }) => {
      state.currentMenu = payload;
    },
    setSelectedCateg: (state, { payload }) => {
      state.selectedCateg = payload;
    },
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setDataAll: (state, { payload }) => {
      state.data_all = payload;
    },
    setDropValue: (state, { payload }) => {
      state.dropValue = payload;
    },
    setDt: (state, { payload }) => {
      const { categorie, articles } = payload;
      const cat = categorie.slice(0, 3);
      state.dt = { ...payload, categorie: cat, allcategorie: categorie };
    },
  },
});

export const {
  setLanguage,
  setNewIdLangue,
  setDt,
  setSlide,
  setDataAll,
  setData,
  setDropValue,
  setCurrentMenu,
  setSelectedCateg,
} = dataSlice.actions;
export default dataSlice.reducer;
