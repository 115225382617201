import React from "react";
import "./Footer.css";
import { config } from "../../../constant";
const Footer = () => {
  const { footer } = config;
  return (
    <footer>
      <span>{footer}</span>
    </footer>
  );
};

export default Footer;
