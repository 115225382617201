import React, { useReducer } from "react";
import "./ActionContent.css";
import { TedModal, TedButton } from "../../core-ui";
import { useDispatch, useSelector } from "react-redux";
import {
  setOpenModal,
  setLoading,
  setError,
  setIsSaved,
  setData,
} from "../../global-state/redux";
import {
  deleteRequest,
  patchRequest,
  postRequest,
} from "../../network/httpRequest";
import { urls } from "../../constant";
import { formatterDateAndHour } from "../../helpers";
import { Formulaire } from "../../components";
import {
  defaultValueFormAnnulerApprovisionnement,
  reducer,
  configFormAnnulerApprovisionnement,
} from "../../reducers";

const urlsAction = [
  urls.user,
  urls.produit + "/categorie",
  urls.produit,
  urls.commande,
];
const statusAppro = [
  {
    status: "En Attente",
    color: "warning",
  },
  {
    status: "Valider partiel",
    color: "info",
  },
  {
    status: "Valider",
    color: "success",
  },
  {
    status: "Annuler",
    color: "danger",
  },
];

const ActionContent = () => {
  const dispatch = useDispatch();
  const { isloading } = useSelector((store) => store.loading);
  const {
    open,
    number,
    actionName,
    actionTitle,
    actionDescription,
    data: contentItem,
  } = useSelector((store) => store.modal);

  if (number === 0) {
    return (
      <ActionMainValidateAppro dispatch={dispatch} isloading={isloading} />
    );
  }
  if (number === 1) {
    return <ActionMainAnnulerAppro dispatch={dispatch} isloading={isloading} />;
  }
  if (number === 2) {
    return (
      <ActionMainDetailsNewMembers dispatch={dispatch} isloading={isloading} />
    );
  }
  if (number === 3) {
    return (
      <ActionMainDetailsCommandes dispatch={dispatch} isloading={isloading} />
    );
  }
  if (number === 4) {
    return (
      <ActionMainValidateCommande dispatch={dispatch} isloading={isloading} />
    );
  }
  if (number === 5) {
    return (
      <ActionProductEnableAndDesable
        dispatch={dispatch}
        isloading={isloading}
      />
    );
  }
};
/*
#######
    ACTION VALIDATE QUANTITE
#######
*/
const ActionMainValidateAppro = ({ dispatch, isloading }) => {
  const {
    open,
    number,
    actionName,
    actionTitle,
    actionDescription,
    data: dataModal,
  } = useSelector((store) => store.modal);
  const validateAppro = async () => {
    dispatch(setLoading());
    const { err, msg, data, typeErr } = await patchRequest(
      `${urls.appro}/validate-quantite/${dataModal.id}`,
      "json"
    );
    if (typeErr === "failed") {
      dispatch(setLoading());
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    dispatch(setLoading());
    dispatch(setError({ err, msg, data, typeErr }));
    dispatch(setOpenModal({ actionName: "", number: 0 }));
    return dispatch(setIsSaved());
  };
  return (
    <TedModal
      title={actionTitle}
      isOpen={open}
      fxCloseAndOpen={() => dispatch(setOpenModal({ number: 0 }))}
      size="moyen-01"
    >
      <div className="action-content">
        {dataModal.status < 2 && (
          <p>
            Vous êtes sur le point d'effectuer
            {dataModal.status === 0 ? " une première" : " une deuxième"}{" "}
            validation de cet approvisionnement
          </p>
        )}

        <div className="">
          <p>
            Article : <span>{dataModal.produit?.translation[0]?.name}</span>
          </p>
          <p>
            Quantité : <span>{dataModal.qte}</span>
          </p>
          <p>
            Fait par :{" "}
            <span>{`${dataModal.createur?.nom} ${dataModal.createur?.prenom}`}</span>
          </p>
          <p>
            Date : <span>{formatterDateAndHour(dataModal.createdAt)}</span>
          </p>
        </div>
      </div>

      {dataModal.status < 2 ? (
        <div className="delete-btn-modal">
          <TedButton
            type="button"
            classe="success"
            label="VALIDER"
            loading={isloading}
            fxOnClick={() => validateAppro()}
          />
        </div>
      ) : (
        <ContentActionMainValidateApproDetailsHistoriqueValidations
          data={dataModal.appro_quantite_details}
        />
      )}
    </TedModal>
  );
};
const ContentActionMainValidateApproDetailsHistoriqueValidations = ({
  data,
}) => {
  return (
    <div className="details-historique-validation">
      <p> Les Actions de validations</p>
      {data.map(
        ({ id, validateur, commentaire, status, createdAt: dateChanging }) => {
          return (
            <div key={id}>
              <p>
                Validateur :{" "}
                <span>{`${validateur.nom} ${validateur.prenom}`}</span>
              </p>
              <p>
                Status :
                <span className={`status ${statusAppro[status].color}`}>
                  {statusAppro[status].status}
                </span>
              </p>
              <p>
                Commentaire : <span>{commentaire}</span>
              </p>
              <p>
                Date et Heure :{" "}
                <span>{formatterDateAndHour(dateChanging)}</span>
              </p>
            </div>
          );
        }
      )}
    </div>
  );
};

const ActionMainAnnulerAppro = ({ dispatch, isloading }) => {
  const {
    open,
    number,
    actionName,
    actionTitle,
    actionDescription,
    data: dataModal,
  } = useSelector((store) => store.modal);
  const [defaultValue, dispatchValue] = useReducer(
    reducer,
    defaultValueFormAnnulerApprovisionnement
  );
  const [configForm, dispatchConfig] = useReducer(
    reducer,
    configFormAnnulerApprovisionnement
  );

  const annulerAppro = async () => {
    const datas = defaultValue;
    dispatch(setLoading());
    const { err, msg, data, typeErr } = await patchRequest(
      `${urls.appro}/annuler/${dataModal.id}`,
      "json",
      datas
    );
    if (typeErr === "failed") {
      dispatch(setLoading());
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    dispatch(setLoading());
    dispatch(setError({ err, msg, data, typeErr }));
    dispatch(setOpenModal({ actionName: "", number: 0 }));
    return dispatch(setIsSaved());
  };
  return (
    <TedModal
      title={actionTitle}
      isOpen={open}
      fxCloseAndOpen={() => dispatch(setOpenModal({ number: 0 }))}
      size="moyen-01"
    >
      <div className="delete-content">
        {dataModal.status === 2 ? (
          <p className="text info bold">
            Impossible d'annuler cet approvisionnement car la 2e validation est
            déjà faite !!
          </p>
        ) : dataModal.status === 3 ? (
          <p className="text danger bold">
            Impossible de proceder à cette action car l'approvisionnement est
            déjà annulé!!
          </p>
        ) : (
          <>
            <p className="text bold">
              Vous êtes sur le point d'annuler cet approvisionnement, êtes vous
              sûr de vouloir bien l'annuler ?
            </p>
            <form>
              <Formulaire
                fields={configForm}
                dispatch={dispatchValue}
                values={defaultValue}
                align="column"
              />
            </form>
          </>
        )}
      </div>
      {dataModal.status < 2 && (
        <div className="delete-btn-modal">
          <TedButton
            type="button"
            classe="danger"
            label="ANNULER"
            loading={isloading}
            fxOnClick={() => annulerAppro()}
          />
        </div>
      )}
    </TedModal>
  );
};

const ActionMainDetailsCommandes = ({ dispatch, isloading }) => {
  const {
    open,
    number,
    actionName,
    actionTitle,
    actionDescription,
    data: dataModal,
  } = useSelector((store) => store.modal);
  return (
    <TedModal
      title={actionTitle}
      isOpen={open}
      fxCloseAndOpen={() => dispatch(setOpenModal({ number: 0 }))}
      size="big"
    >
      <div className="action-content">
        <div className="action-content_info_personnelle">
          <div className="action-content_identite">
            <p>
              Nom & Prénom :
              <span>{` ${dataModal.first_name} ${dataModal.last_name}`}</span>
            </p>
            <p>
              UMVA Card :
              <span>{` ${
                dataModal.umva_cardid ? dataModal.umva_cardid : "-"
              }`}</span>
            </p>
            <p>
              UMVA ID :
              <span>{` ${dataModal.umva_id ? dataModal.umva_id : "-"}`}</span>
            </p>
            <p>
              Uban :<span>{` ${dataModal.uban ? dataModal.uban : "-"}`}</span>
            </p>
            <p>
              Type membre :
              <span>{` ${dataModal.has_umva ? "ANCIEN" : "NOUVEAU"}`}</span>
            </p>
          </div>
          <div className="action-content_addresse">
            <p>
              Province :
              <span>{` ${dataModal.province ? dataModal.province : "-"}`}</span>
            </p>
            <p>
              Commune :
              <span>{` ${dataModal.commune ? dataModal.commune : "-"}`}</span>
            </p>
            <p>
              Zone :<span>{` ${dataModal.zone ? dataModal.zone : "-"}`}</span>
            </p>
            <p>
              Colline :
              <span>{` ${dataModal.colline ? dataModal.colline : "-"}`}</span>
            </p>
            <p>
              Localité & groupe :
              <span>{` ${dataModal.localite ? dataModal.localite : "-"} ${
                dataModal.group ? dataModal.group : "-"
              }`}</span>
            </p>
          </div>
        </div>
        <div className="action-content_info_personnelle">
          <div className="">
            <p>
              Date : <span>{formatterDateAndHour(dataModal.date)}</span>
            </p>
            <p>
              Catégorie :{" "}
              <span>{dataModal.categorie?.translation[0]?.description}</span>
            </p>
            <p>
              Article :{" "}
              <span>{dataModal.commande_articles?.translation[0]?.name}</span>
            </p>
            <p>
              Quantité : <span>{dataModal.qte}</span>
            </p>
          </div>
          <div className="">
            <p>
              Status :{" "}
              <span className={`status ${dataModal.status.color}`}>
                {dataModal.status.description}
              </span>
            </p>
            <p>
              Vendeur :
              <span>
                {dataModal.vendeur_data
                  ? dataModal.vendeur_data.nom +
                    " " +
                    dataModal.vendeur_data.prenom
                  : "-"}
              </span>
            </p>
          </div>
        </div>
      </div>
    </TedModal>
  );
};
const ActionMainValidateCommande = ({ dispatch, isloading }) => {
  const {
    open,
    number,
    actionName,
    actionTitle,
    actionDescription,
    data: dataModal,
  } = useSelector((store) => store.modal);
  const { data: commandes, dropValue } = useSelector((store) => store.data);

  const validateCommande = async () => {
    dispatch(setLoading());
    let datas = { commandes: [...commandes] };
    const { err, msg, data, typeErr } = await postRequest(
      `${urls.commande}/valider-commande`,
      "json",
      datas
    );
    if (typeErr === "failed") {
      dispatch(setLoading());
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    dispatch(setLoading());
    dispatch(setError({ err, msg, data, typeErr }));
    dispatch(setOpenModal({ actionName: "", number: 0 }));
    dispatch(setData([]));
    return dispatch(setIsSaved());
  };
  return (
    <TedModal
      title={actionTitle}
      isOpen={open}
      fxCloseAndOpen={() => dispatch(setOpenModal({ number: 0 }))}
      size="moyen-01"
    >
      <div className="action-content cmd-validation">
        <p>
          Vous êtes sur le point de valider {commandes.length} commande
          {commandes.length > 1 ? "s" : ""}.
        </p>
      </div>

      <div className="delete-btn-modal">
        <TedButton
          type="button"
          classe="success"
          label="VALIDER"
          loading={isloading}
          fxOnClick={() => validateCommande()}
        />
      </div>
    </TedModal>
  );
};

const ActionMainDetailsNewMembers = ({ dispatch, isloading }) => {
  const {
    open,
    number,
    actionName,
    actionTitle,
    actionDescription,
    data: dataModal,
  } = useSelector((store) => store.modal);
  return (
    <TedModal
      title={actionTitle}
      isOpen={open}
      fxCloseAndOpen={() => dispatch(setOpenModal({ number: 0 }))}
      size="moyen-01"
    >
      <div className="action-content">
        <div className="action-content_info_personnelle">
          <div className="action-content_identite">
            <p>
              Nom & Prénom :
              <span>{` ${dataModal.first_name} ${dataModal.last_name}`}</span>
            </p>
            <p>
              Gender :<span>{dataModal.gender}</span>
            </p>
            <p>
              UMVA Card :
              <span>{` ${
                dataModal.umva_cardid ? dataModal.umva_cardid : "-"
              }`}</span>
            </p>
            <p>
              Balance Actuelle :<span>{dataModal.currentbalance} Fbu</span>
            </p>
          </div>
          <div className="action-content_addresse">
            <p>
              Province :
              <span>{` ${dataModal.province ? dataModal.province : "-"}`}</span>
            </p>
            <p>
              Commune :
              <span>{` ${dataModal.commune ? dataModal.commune : "-"}`}</span>
            </p>
            <p>
              Zone :<span>{` ${dataModal.zone ? dataModal.zone : "-"}`}</span>
            </p>
            <p>
              Colline :
              <span>{` ${dataModal.colline ? dataModal.colline : "-"}`}</span>
            </p>
            <p>
              Localité & groupe :
              <span>{` ${dataModal.localite ? dataModal.localite : "-"} ${
                dataModal.group ? dataModal.group : "-"
              }`}</span>
            </p>
          </div>
        </div>
      </div>
    </TedModal>
  );
};
const ActionProductEnableAndDesable = ({ dispatch, isloading }) => {
  const {
    open,
    number,
    actionName,
    actionTitle,
    actionDescription,
    data: dataModal,
  } = useSelector((store) => store.modal);
  const { data: commandes, dropValue } = useSelector((store) => store.data);

  const fxAction = async () => {
    dispatch(setLoading());
    let datas = {};
    const { err, msg, data, typeErr } = await patchRequest(
      `${urls.produit}/enable-desable/${dataModal.id}`,
      "json",
      datas
    );
    if (typeErr === "failed") {
      dispatch(setLoading());
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    dispatch(setLoading());
    dispatch(setError({ err, msg, data, typeErr }));
    dispatch(setOpenModal({ actionName: "", number: 0 }));
    dispatch(setData([]));
    return dispatch(setIsSaved());
  };
  return (
    <TedModal
      title={actionTitle}
      isOpen={open}
      fxCloseAndOpen={() => dispatch(setOpenModal({ number: 0 }))}
      size="moyen-01"
    >
      <div className="action-content cmd-validation">
        <p>
          Vous êtes sur le point de
          {`${dataModal.isActive > 0 ? "désactiver" : "activer"} ${
            dataModal.translation && dataModal.translation[0]?.name
          }`}
        </p>
      </div>

      <div className="delete-btn-modal">
        <TedButton
          type="button"
          classe={dataModal.isActive > 0 ? "danger" : "success"}
          label={dataModal.isActive > 0 ? "DESACTIVER" : "ACTIVER"}
          loading={isloading}
          fxOnClick={() => fxAction()}
        />
      </div>
    </TedModal>
  );
};
export default ActionContent;
