import React, { useEffect } from "react";
import { icons, urls } from "../../../../constant";
import { useDispatch, useSelector } from "react-redux";
import { setOpenModal } from "../../../../global-state/redux";
import { TedEmpty } from "../../../../core-ui";

const configHeaderTable = [
  "Cover",
  "Type",
  "Description",
  "Nb article(s)",
  "Action",
];

const ListCategorie = ({ data }) => {
  const dispatch = useDispatch();

  return (
    <div>
      <table className="table-normal">
        <thead>
          <tr>
            {configHeaderTable.map((i, idx) => {
              return <td key={idx}>{i}</td>;
            })}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item) => {
              return (
                <tr key={item.id}>
                  <td>
                    <div className="figure-div">
                      <img
                        src={
                          process.env.REACT_APP_URL_PUBLIC_FOLDER +
                          item.path_image_web
                        }
                        alt={
                          item.translation && item.translation[0]?.description
                        }
                      />
                    </div>
                  </td>
                  <td>{item.type?.name}</td>
                  <td>
                    {item.translation && item.translation[0]?.description}
                  </td>
                  <td>{item.n_produit}</td>
                  <td>
                    <div className="actions">
                      <div
                        className="btn info"
                        title="Ajouter dans une autre langue"
                        onClick={() =>
                          dispatch(
                            setOpenModal({
                              actionName: "addInLanguage",
                              data: {
                                id: item.id,
                                uuid: item.uuid,
                                typeId: item.typeId,
                                description:
                                  item.translation &&
                                  item.translation[0]?.description,
                              },
                            })
                          )
                        }
                      >
                        <span>{icons.langue}</span>
                      </div>
                      <div
                        className="btn success"
                        title="Modifier"
                        onClick={() =>
                          dispatch(
                            setOpenModal({
                              actionName: "updateData",
                              data: {
                                id: item.id,
                                uuid: item.uuid,
                                typeId: item.typeId,
                                description:
                                  item.translation &&
                                  item.translation[0]?.description,
                                pathImage: item.path_image_web,
                              },
                            })
                          )
                        }
                      >
                        <span>{icons.edit}</span>
                      </div>

                      <div
                        className="btn danger"
                        title="Supprimer"
                        onClick={() =>
                          dispatch(
                            setOpenModal({
                              actionName: "deleteContent",
                              data: item,
                              actionTitle: `suppression de la categoire ${
                                item.translation &&
                                item.translation[0]?.description
                              }`,
                              number: 1,
                            })
                          )
                        }
                      >
                        <span>{icons.deleted}</span>
                        {/* <span>Supprimer</span> */}
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {data && data.length < 1 && (
        <TedEmpty
          description={"Aucune catégorie disponible"}
          icon={icons.empty}
        />
      )}
    </div>
  );
};

export default ListCategorie;
