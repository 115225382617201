import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setOpenModal,
  setLoginUser,
  setCurrentMenu,
} from "../../../../global-state/redux";
import { images } from "../../../../constant";

const Header = () => {
  const dispatch = useDispatch();
  const { dataUser, isConnected } = useSelector((store) => store.user);
  const { currentMenu } = useSelector((store) => store.data);
  useEffect(() => {}, [dataUser]);

  const _logout = () => {
    sessionStorage.clear();
    dispatch(setLoginUser({ dataUser: [], isConnected: false }));
  };
  return (
    <div className="header">
      <div className="header-center">
        <div>
          <Link to="/">
            <figure>
              <img src={images.logo_seed} alt="logo site" />
            </figure>
          </Link>
        </div>
        <nav className="site">
          <ul>
            <li
              className={`${currentMenu === 1 ? "active" : "no-active"}`}
              onClick={() => dispatch(setCurrentMenu(1))}
            >
              semences
            </li>
            <li
              className={`${currentMenu === 2 ? "active" : "no-active"}`}
              onClick={() => dispatch(setCurrentMenu(2))}
            >
              produits
            </li>
            <li
              className={`${currentMenu === 3 ? "active" : "no-active"}`}
              onClick={() => dispatch(setCurrentMenu(3))}
            >
              autres
            </li>
          </ul>
        </nav>
        <div>
          {isConnected ? (
            <div className="user-connecter">
              <div>
                <div className="avatar">{`${dataUser.auth.nom[0].toUpperCase()}`}</div>
              </div>
              <div>
                <p>{`${dataUser.auth.nom.toUpperCase()} ${
                  dataUser.auth.prenom
                }`}</p>
                <span>{dataUser?.auth?.role.description}</span>
              </div>
              <div>
                <button type="button" className="danger" onClick={_logout}>
                  Deconnexion
                </button>
              </div>
            </div>
          ) : (
            <button
              type="button"
              className="primary"
              onClick={() => {
                dispatch(setOpenModal({ actionName: "connected" }));
              }}
            >
              Se Connecter
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
