import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setError } from "../global-state/redux";
import { urls } from "../constant";
import { getRequest } from "../network/httpRequest";

const useGetData = (url) => {
  const dispatch = useDispatch();
  const [loading, seLoading] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    getData();
  }, [url]);
  const getData = async () => {
    seLoading(true);
    const { err, msg, data, typeErr } = await getRequest(url);
    setTimeout(() => {
      seLoading(false);
    }, 2000);
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    setData(data);
  };

  return [loading, data, getData];
};

export default useGetData;
