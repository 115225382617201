import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError, setLoading } from "../../global-state/redux";
import "./Approvisionnements.css";
import { data, urls, menu } from "../../constant";
import { getRequest } from "../../network/httpRequest";
import { Container, MenuPager, HeaderPager } from "../../components";
import { ListApprovisionnement } from "./small";
import { ActionContent } from "../../reusables";
const tabs = menu.tabsApprovisionnements;
const Approvisionnements = () => {
  const [tabIndex, setTabIndex] = useState(tabs[0]);
  const { open, data, numberopen, actionName, number } = useSelector(
    (store) => store.modal
  );
  const tabChanging = () => {};
  return (
    <>
      <Container pageName={"Approvisionnements"}>
        {/* <HeaderPager title={tabIndex.description} /> */}
        <MenuPager tabs={tabs} fxSelectedtab={tabChanging} />
        <ListApprovisionnement />
      </Container>
    </>
  );
};

export default Approvisionnements;
