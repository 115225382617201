import React, { useEffect, useState, useReducer, useRef } from "react";
import { urls, images, icons } from "../../../constant";
import { useSelector, useDispatch } from "react-redux";
import {
  setError,
  setLoading,
  setIsSaved,
  setOpenModal,
  setData,
  setDropValue,
} from "../../../global-state/redux";
import { getRequest, postRequest } from "../../../network/httpRequest";
import { formatterDateAndHour } from "../../../helpers";
import {
  TedEmpty,
  TedDropdown,
  TedCheckBox,
  TedButton,
} from "../../../core-ui";
import { Formulaire } from "../../../components";
import {
  configFormFiltreRapportCommande,
  defaultValueFormFiltreRapportCommande,
  reducer,
} from "../../../reducers";
import { useDownloadExcel } from "react-export-table-to-excel";
const configHeaderTable = [
  "Date",
  "UMVA ID",
  "Nom & Prénom",
  "UMVA Card",
  "Uban",
  "Province",
  "Commune",
  "Zone",
  "Article",
  "Qte",
  "Status",
];

const RapportCommandes = ({ data }) => {
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `RAPPORT COMMANDE UMVA Market DU ${new Date()}`,
    sheet: "COMMANDES",
  });

  const [dataCommande, setDataCommande] = useState([]);
  const {
    dataUser: {
      user: {
        auth: { roleId },
      },
    },
  } = useSelector((store) => store.user);
  const { isSaved } = useSelector((store) => store.loading);
  //   const { data: commandes } = useSelector((store) => store.data);
  const dispatch = useDispatch();
  const getCommandes = async (dataForm) => {
    dispatch(setLoading());
    const datas = dataForm;
    const { err, msg, data, typeErr } = await postRequest(
      `${urls.rapport}/commandes?lang=fr`,
      "json",
      datas
    );
    dispatch(setLoading());
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    setDataCommande(data);
  };
  useEffect(() => {
    // getCommandes();
  }, [isSaved]);

  return (
    <div>
      <div>
        <FiltreRapportCommandes
          data={data}
          fxFilter={getCommandes}
          fxDownloadXls={onDownload}
          tableData={dataCommande}
        />
      </div>
      <table className="table-normal">
        <thead>
          <tr>
            {configHeaderTable.map((i, idx) => {
              return <td key={idx}>{i}</td>;
            })}
          </tr>
        </thead>
        <tbody>
          {dataCommande &&
            dataCommande.map((item) => {
              const {
                id,
                umva_id,
                first_name,
                last_name,
                umva_cardid,
                uban,
                has_umva,
                province,
                commune,
                zone,
                colline,
                localite,
                group,
                commande_articles,
                qte,
                status,
                vendeur_data,
                createdAt,
                date,
              } = item;
              return (
                <tr key={id}>
                  <td>{date}</td>
                  <td>{umva_id ? umva_id : "-"}</td>
                  <td>{`${first_name} ${last_name}`}</td>
                  <td>{umva_cardid}</td>
                  <td>{uban}</td>
                  <td>{province}</td>
                  <td>{commune}</td>
                  <td>{zone}</td>
                  <td>
                    {commande_articles &&
                      commande_articles.translation[0]?.name}
                  </td>
                  <td>{qte}</td>
                  <td>
                    <span className={`status ${status.color}`}>
                      {status.description}
                    </span>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      {dataCommande.length < 1 && (
        <TedEmpty description={"Aucune commande trouvée"} icon={icons.empty} />
      )}

      {/* excel to download */}
      <div style={{ display: "none" }}>
        <table ref={tableRef}>
          <tbody>
            <tr>
              <th>Date</th>
              <th>UMVA ID</th>
              <th>Nom</th>
              <th>Prenom</th>
              <th>UMVA CARD</th>
              <th>UBAN</th>
              <th>Province</th>
              <th>Commune</th>
              <th>Zone</th>
              <th>Type</th>
              <th>Catégorie</th>
              <th>Article</th>
              <th>Qte</th>
              <th>Prix Unitaire</th>
              <th>Prix Total</th>
              <th>Status</th>
            </tr>
            {dataCommande &&
              dataCommande.map((item) => {
                const {
                  id,
                  umva_id,
                  first_name,
                  last_name,
                  umva_cardid,
                  uban,
                  has_umva,
                  province,
                  commune,
                  zone,
                  colline,
                  localite,
                  group,
                  commande_articles,
                  qte,
                  status,
                  vendeur_data,
                  createdAt,
                  date,
                  prixUnitaire,
                  categorie,
                  categ_type,
                } = item;
                return (
                  <tr key={id}>
                    <td>{date}</td>
                    <td>{umva_id ? umva_id : "-"}</td>
                    <td>{first_name}</td>
                    <td>{last_name}</td>
                    <td>{umva_cardid}</td>
                    <td>{uban}</td>
                    <td>{province}</td>
                    <td>{commune}</td>
                    <td>{zone}</td>
                    <td>{categ_type?.name}</td>
                    <td>
                      {categorie && categorie.translation[0]?.description}
                    </td>
                    <td>
                      {commande_articles &&
                        commande_articles.translation[0]?.name}
                    </td>
                    <td>{qte}</td>
                    <td>{parseFloat(prixUnitaire)}</td>
                    <td>{parseFloat(prixUnitaire) * parseFloat(qte)}</td>
                    <td>
                      <span className={`status ${status.color}`}>
                        {status.description}
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const FiltreRapportCommandes = ({
  data,
  fxFilter,
  fxDownloadXls,
  tableData,
}) => {
  const { isloading } = useSelector((store) => store.loading);
  const [configForm, dispatchConfig] = useReducer(
    reducer,
    configFormFiltreRapportCommande
  );
  const [defaultValue, dispatchDefautlValue] = useReducer(
    reducer,
    defaultValueFormFiltreRapportCommande
  );
  useEffect(() => {
    if (Object.keys(data).length > 0)
      dispatchConfig({
        type: "SET_FILTRE_RAPPORT_COMMANDE_DATA",
        payload: data,
      });
  }, [data]);

  useEffect(() => {
    if (Object.keys(data).length > 0)
      dispatchConfig({
        type: "SET_FILTRE_DEPEND_CATEGORIE_COMMANDE_DATA",
        payload: { typeId: defaultValue.typeId, categories: data.categories },
      });
  }, [defaultValue.typeId]);

  useEffect(() => {
    if (Object.keys(data).length > 0)
      dispatchConfig({
        type: "SET_FILTRE_DEPEND_ARTICLE_COMMANDE_DATA",
        payload: { categId: defaultValue.categId, articles: data.produits },
      });
  }, [defaultValue.categId]);

  return (
    <div>
      <div>
        <Formulaire
          fields={configForm.firstOne}
          dispatch={dispatchDefautlValue}
          values={defaultValue}
          align="row"
        />
        <Formulaire
          fields={configForm.secondOne}
          dispatch={dispatchDefautlValue}
          values={defaultValue}
          align="row"
        />
      </div>
      <div className="save-btn-filtre">
        <div>
          {tableData.length > 0 && (
            <TedButton
              type="button"
              classe="success"
              label="EXPORTER RAPPORT EN EXCEL"
              loading={isloading}
              fxOnClick={() => fxDownloadXls()}
            />
          )}
        </div>

        <div>
          <TedButton
            type="button"
            classe="info"
            label="APPLIQUER FILTRE"
            loading={isloading}
            fxOnClick={() => fxFilter(defaultValue)}
          />
        </div>
      </div>
    </div>
  );
};

export default RapportCommandes;
