import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError, setLoading, setOpenModal } from "../../global-state/redux";
import "./Commandes.css";
import { data, urls, menu } from "../../constant";
import { getRequest } from "../../network/httpRequest";
import { Container, MenuPager, HeaderPager } from "../../components";
import { ListCommandes, ImportFile, AffecterCommande } from "./small";

const Commandes = () => {
  //configuration pour le role
  const {
    dataUser: {
      user: {
        auth: { roleId },
      },
    },
  } = useSelector((store) => store.user);
  let tabs = menu.tabsCommandes;
  //tabs si le role est vendeur
  if (roleId === 2) {
    tabs = tabs.filter((item) => item.id > 1);
  }

  const [tabIndex, setTabIndex] = useState(tabs[0]);
  const [vendeurs, setVendeurs] = useState([]);
  const { open, actionName, number } = useSelector((store) => store.modal);
  const { data } = useSelector((store) => store.data);
  const dispatch = useDispatch();
  const tabChanging = (data) => {
    setTabIndex(data);
  };

  const getVendeurs = async () => {
    dispatch(setLoading());
    const { err, msg, data, typeErr } = await getRequest(
      `${urls.statique}/users?role=2`
    );
    dispatch(setLoading());
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    setVendeurs(data);
  };

  useEffect(() => {
    getVendeurs();
  }, [data]);
  return (
    <>
      <Container pageName={"commandes"}>
        <HeaderPager
          title={tabIndex.description}
          btnTitle="Importer Excel"
          showBtn={tabIndex.id < 2 ? true : false}
        />
        <MenuPager
          tabs={tabs}
          fxSelectedtab={tabChanging}
          defaultTab={roleId === 2 ? 2 : 0}
        />
        <BtnAffectation
          classCss={`${
            data.length > 0 && roleId === 1 ? "show-btn" : "hidden-btn"
          }`}
        />
        {data.length > 0 && roleId === 2 && <ValiderCommande />}

        <ListCommandes tabIndex={tabIndex} />
      </Container>
      {tabIndex.id === 0 && open && actionName === "createData" && (
        <ImportFile />
      )}
      {tabIndex.id === 0 && open && actionName === "affecterCommande" && (
        <AffecterCommande vendeurs={vendeurs} />
      )}
    </>
  );
};

const BtnAffectation = ({ classCss }) => {
  const dispatch = useDispatch();
  return (
    <div className={`btn-affections ${classCss}`}>
      <div>
        <button
          className="btn info"
          onClick={() => {
            dispatch(setOpenModal({ actionName: "affecterCommande" }));
          }}
        >
          Affecter au vendeur
        </button>
      </div>
    </div>
  );
};

const ValiderCommande = () => {
  const dispatch = useDispatch();
  return (
    <div className="btn-affections">
      <div>
        <button
          className="btn info"
          onClick={() => {
            dispatch(
              setOpenModal({
                actionName: "actionContent",
                data: [],
                actionTitle: `VALIDATION DE COMMANDES`,
                number: 4,
              })
            );
          }}
        >
          Valider
        </button>
      </div>
    </div>
  );
};

export default Commandes;
