import React from "react";
import { TedInput, TedInputFile, TedCheckBox } from "../../core-ui";
import "./Form.css";
import PropTypes from "prop-types";
const Formulaire = ({ fields, dispatch, values, align }) => {
  return (
    <div className={`form ${align ? align : "column"}`}>
      {fields.map((item, idx) => {
        const { icon, type, name, label, options, accept } = item;
        return type === "file" ? (
          <TedInputFile
            key={idx}
            name={name}
            accept={accept}
            label={label}
            icons={icon}
            fxOnchange={(e) => {
              dispatch({
                type: "SET_FORM_INPUT_VALUE",
                payload: {
                  name: e.target.name,
                  value: e.target.files[0],
                  multiselect: e.target.multiselect,
                },
              });
            }}
          />
        ) : type === "checkbox" ? (
          <div key={idx}>
            <TedCheckBox
              name={name}
              label={label}
              fxOnchange={(e) => {
                dispatch({
                  type: "SET_FORM_INPUT_VALUE",
                  payload: {
                    name: e.target.name,
                    value: e.target.value,
                    multiselect: e.target.multiselect,
                  },
                });
              }}
            />
          </div>
        ) : (
          <div key={idx}>
            <TedInput
              value={values[name]}
              icon={icon}
              type={type}
              fxOnchange={(e) => {
                dispatch({
                  type: "SET_FORM_INPUT_VALUE",
                  payload: {
                    name: e.target.name,
                    value: e.target.value,
                    values: e.target.values,
                    multiselect: e.target.multiselect,
                  },
                });
              }}
              name={name}
              label={label}
              options={options}
            />
          </div>
        );
      })}
    </div>
  );
};

Formulaire.propTypes = {
  fields: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  align: PropTypes.oneOf(["row", "column"]).isRequired,
};

export default Formulaire;
