import { data } from "../constant";

const reducer = (state, action) => {
  if (action.type === "CHANGE_CLASS_LOADER_TO_HIDDEN") {
    return {
      ...state,
      loaderClass: "hidden",
      containerClass: "shown",
    };
  }
  if (action.type === "SET_FORM_INPUT_VALUE") {
    let { name, value, values, multiselect } = action.payload;
    if (typeof multiselect === "object") {
      value = multiselect;
    }

    return {
      ...state,
      //[name]: value > 0 ? value : values,
      [name]: typeof values === "undefined" ? value : values,
    };
  }
  if (action.type === "SET_TABLES_DATA") {
    const data = action.payload;
    return {
      ...state,
      datas: data,
    };
  }
  if (action.type === "SET_USER_INPUT_OPTIONS_VENDEURS_DATA") {
    const newState = state;
    const { vendeurs: data } = action.payload;
    const vendeurs = data.map(({ id, nom, prenom }) => {
      return {
        id,
        description: `${nom} ${prenom}`,
      };
    });
    newState[0].options = vendeurs;
    return [...newState];
  }

  if (action.type === "SET_SELECT_OPTION_DATA_CATEGORIE_ARTICLE") {
    const newState = state;
    const data = action.payload;
    const categorie = data.map(({ id, translation }) => {
      return {
        id,
        description: translation[0]?.description,
      };
    });
    newState[0].options = categorie;
    return [...newState];
  }
  if (action.type === "SET_DEPENDANT_ARTICLE_FOR_SELECTED_CATEGORIE") {
    const newState = state;
    const data = action.payload;
    var { categId, articles } = data;
    articles = articles.filter((item) => item.categId === categId);
    const art_list = articles.map(({ id, translation }) => {
      return {
        id,
        description: translation[0]?.name,
      };
    });
    newState[1].options = art_list;
    return [...newState];
  }
  if (action.type === "SET_USER_DATA") {
    const newState = state;
    const { provinces, roles } = action.payload;
    const province = provinces.map(({ id, name }) => {
      return {
        id,
        description: name,
      };
    });
    newState.firstOne[2].options = roles;
    newState.fiveOne[0].options = province;

    return {
      ...state,
      ...newState,
    };
  }

  if (action.type === "SET_DEPENDANT_COMMUNE") {
    const newState = state;
    const { provinceId, communes } = action.payload;
    const commune = communes.filter((item) => item.provinceId === provinceId);
    const com = commune.map(({ id, name, provinceId }) => {
      return {
        id,
        description: name,
      };
    });
    newState.fiveOne[1].options = com;
    return {
      ...state,
      ...newState,
    };
  }
  if (action.type === "SET_DEPENDANT_ZONE") {
    const newState = state;
    const { communeId, zones } = action.payload;
    // console.log(communeId, zones);
    const datas = zones.filter((item) => item.communeId === communeId);
    const dt = datas.map(({ id, name, communeId }) => {
      return {
        id,
        description: name,
      };
    });
    newState.thirdOne[0].options = dt;
    return {
      ...state,
      ...newState,
    };
  }
  if (action.type === "SET_DEPENDANT_COLLINE") {
    const newState = state;
    const { zoneId, collines } = action.payload;
    const datas = collines.filter((item) => item.zoneId === zoneId);
    const dt = datas.map(({ id, name, zoneId }) => {
      return {
        id,
        description: name,
      };
    });
    newState.thirdOne[1].options = dt;
    return {
      ...state,
      ...newState,
    };
  }
  if (action.type === "SET_DEPENDANT_LOCALITE") {
    const newState = state;
    const { collineId, localites } = action.payload;
    const datas = localites.filter((item) => item.collineId === collineId);
    const dt = datas.map(({ id, name, collineId }) => {
      return {
        id,
        description: name,
      };
    });
    newState.thirdOne[2].options = dt;
    return {
      ...state,
      ...newState,
    };
  }

  if (action.type === "SET_FILTRE_RAPPORT_COMMANDE_DATA") {
    const newState = state;
    const { provinces, commnues, zones, categories, types, produits, status } =
      action.payload;

    const province = provinces.map(({ province }) => {
      return {
        id: province,
        description: province,
      };
    });
    const commune = commnues.map(({ commune }) => {
      return {
        id: commune,
        description: commune,
      };
    });
    const zone = zones.map(({ zone }) => {
      return {
        id: zone,
        description: zone,
      };
    });
    // const categorie = categories.map(({ id, translation }) => {
    //   return {
    //     id,
    //     description: translation[0]?.description,
    //   };
    // });
    const stat = status.map(({ id, description }) => {
      return {
        id,
        description,
      };
    });
    newState.firstOne[0].options = province;
    newState.firstOne[1].options = commune;
    newState.firstOne[2].options = zone;
    newState.firstOne[3].options = types;
    newState.secondOne[1].options = stat;
    return {
      ...state,
      newState,
    };
  }
  if (action.type === "SET_FILTRE_DEPEND_CATEGORIE_COMMANDE_DATA") {
    const newState = state;
    const data = action.payload;
    var { typeId, categories } = data;
    if (categories.length > 0) {
      categories = categories.filter((item) => item.typeId === typeId);
      const prod = categories.map(({ id, translation }) => {
        return {
          id,
          description: translation[0]?.description,
        };
      });
      newState.firstOne[4].options = prod;
    }

    return {
      ...state,
      newState,
    };
  }
  if (action.type === "SET_FILTRE_DEPEND_ARTICLE_COMMANDE_DATA") {
    const newState = state;
    const data = action.payload;
    var { categId, articles } = data;
    articles = articles.filter((item) => item.categId === categId);
    const prod = articles.map(({ id, translation }) => {
      return {
        id,
        description: translation[0]?.name,
      };
    });
    newState.secondOne[0].options = prod;
    return {
      ...state,
      newState,
    };
  }
  return state;
};

export default reducer;
