import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TedModal, TedButton } from "../../../../core-ui";
import {
  setOpenModal,
  setLoading,
  setError,
} from "../../../../global-state/redux";
import { urls } from "../../../../constant";
import { postRequest } from "../../../../network/httpRequest";

const PopupCommande = () => {
  const dispatch = useDispatch();
  const { dataUser, isConnected } = useSelector((store) => store.user);
  const { data } = useSelector((store) => store.modal);

  const [qte, setQte] = useState(0);
  const { isloading } = useSelector((store) => store.loading);

  const passCommande = async () => {
    dispatch(setLoading());
    const datas = {
      umva_id: dataUser.username,
      first_name: dataUser.auth.nom,
      last_name: dataUser.auth.prenom,
      has_umva: 1,
      umva_cardid: "00909890",
      uban: "2674186",
      province: "GITEGA",
      commune: "GITEGA",
      zone: "RURALE",
      colline: "",
      localite: "",
      group: "",
      categId: data.list_produit.id,
      produitId: data.id,
      qte: qte,
      prixUnitaire: data.prix,
    };

    const {
      err,
      msg,
      data: dt,
      typeErr,
    } = await postRequest(urls.commande, "json", datas);
    if (typeErr === "failed") {
      dispatch(setLoading());
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    dispatch(setLoading());
    dispatch(setError({ err, msg, data, typeErr }));
    dispatch(setOpenModal({ number: 0 }));
  };

  return (
    <TedModal
      title={""}
      isOpen={true}
      fxCloseAndOpen={() => dispatch(setOpenModal({ number: 0 }))}
      size="big"
    >
      <div className="commande-form">
        <div className="img">
          <img
            src={process.env.REACT_APP_URL_PUBLIC_FOLDER + data.path_image_web}
            alt={data.translation[0]?.name}
          />
        </div>
        <div className="commande-data">
          <h3>{data.translation[0]?.name}</h3>
          <p className="categorie">
            catégorie :{" "}
            <span>{data.list_produit.translation[0]?.description}</span>
          </p>
          <span className="description">
            {data.translation[0]?.description}
          </span>
          <p className="price">BIF {data.prix}</p>
          {isConnected ? (
            <div className="formulaire-site">
              <input type="text" value={dataUser.username} disabled />
              <div>
                <div>
                  <input
                    type="number"
                    placeholder="Quantité"
                    onChange={(e) => {
                      setQte(e.target.value);
                    }}
                  />
                </div>
                {/* <div>
                  <input type="text" value={"KG"} disabled />
                </div> */}
              </div>
              <div>
                <TedButton
                  type="button"
                  classe="primary"
                  label="Payer avec N'iCash"
                  loading={isloading}
                  fxOnClick={() => passCommande()}
                />
              </div>
            </div>
          ) : (
            <div>
              <span className="description">
                Veuillez svp vous connectez avant de proceder à cette commande!
              </span>
              <div className="popup-btn">
                <TedButton
                  type="button"
                  classe="primary"
                  label="Se connecter"
                  loading={false}
                  fxOnClick={() => {
                    dispatch(setOpenModal({ actionName: "" }));
                    dispatch(setOpenModal({ actionName: "connected" }));
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </TedModal>
  );
};

export default PopupCommande;
