import React, { useEffect, useState } from "react";
import { icons } from "../../constant";
import { useSelector } from "react-redux";

const TedCheckBox = ({ name, label, fxOnchange, desabled }) => {
  const { dropValue } = useSelector((store) => store.data);
  const [state, setState] = useState(false);
  const changedState = (e) => {
    setState(!state);
    e.target.name = name;
    e.target.value = state ? 0 : 1;
    fxOnchange(e);
  };
  const fxDisabled = () => {};
  // const fxReset = () => {
  //   console.log("reset");
  // };
  useEffect(() => {
    setState(false);
  }, [dropValue]);

  return (
    <div
      className={`checkbox ${desabled && "desabled"}`}
      onClick={!desabled ? changedState : fxDisabled}
    >
      <div className={`checkbox-btn ${state ? "active" : "no-active"}`}>
        {state && <span>{icons.checked_input}</span>}
      </div>
      <span>{label}</span>
    </div>
  );
};

export default TedCheckBox;
