import React, { useEffect, useState } from "react";
import { urls, images, icons } from "../../../../constant";
import { useSelector, useDispatch } from "react-redux";
import {
  setError,
  setLoading,
  setIsSaved,
  setOpenModal,
} from "../../../../global-state/redux";
import { TedEmpty } from "../../../../core-ui";
import { useGetFxData } from "../../../../hooks";
import { Skelton, PaginationItems } from "../../../../components";
const configHeaderTable = [
  "Cover",
  "Nom",
  "Description",
  "Prix (Fbu)",
  "Categorie",
  "Status",
  "Action",
];

const ListArticles = () => {
  const { lang: deflang } = useSelector((store) => store.data);
  const [loading, fxData, getFxData] = useGetFxData();
  const { isSaved } = useSelector((store) => store.loading);
  const dispatch = useDispatch();
  const getData = async (page = 1) => {
    await getFxData(`${urls.produit}?lang=${deflang}&page=${page}`);
  };
  useEffect(() => {
    getData();
  }, [isSaved, deflang]);
  return (
    <div>
      <table className="table-normal">
        <thead>
          <tr>
            {configHeaderTable.map((i, idx) => {
              return <td key={idx}>{i}</td>;
            })}
          </tr>
        </thead>
        <tbody>
          {!loading &&
            fxData.rows &&
            fxData.rows.length > 0 &&
            fxData.rows.map((item) => {
              const {
                id,
                uuid,
                path_image_web,
                translation,
                prix,
                isActive,
                list_produit,
              } = item;
              return (
                <tr key={id}>
                  <td>
                    <div className="figure-div">
                      <img
                        src={
                          process.env.REACT_APP_URL_PUBLIC_FOLDER +
                          path_image_web
                        }
                        alt={translation && translation[0]?.name}
                      />
                    </div>
                  </td>
                  <td>{translation && translation[0]?.name}</td>
                  <td>{translation && translation[0]?.description}</td>
                  <td>{prix}</td>
                  <td>
                    {list_produit.translation &&
                      list_produit.translation[0]?.description}
                  </td>
                  <td>
                    <span
                      className={`status ${
                        isActive > 0 ? "success" : "warning"
                      }`}
                    >
                      {isActive > 0 ? "Activé" : "Désactivé"}
                    </span>
                  </td>
                  <td>
                    <div className="actions">
                      <div
                        className="btn info"
                        title="Ajouter dans une nouvelle langue"
                        onClick={() =>
                          dispatch(
                            setOpenModal({
                              actionName: "addInLanguage",
                              data: {
                                id: item.id,
                                uuid: item.uuid,
                                categId: item.categId,
                                prix: item.prix,
                                name:
                                  item.translation && item.translation[0]?.name,
                              },
                            })
                          )
                        }
                      >
                        <span>{icons.langue}</span>
                      </div>
                      <div
                        className="btn warning"
                        title={
                          isActive > 0
                            ? "Désactivé l'article"
                            : "Activé l'article"
                        }
                        onClick={() =>
                          dispatch(
                            setOpenModal({
                              actionName: "actionContent",
                              data: item,
                              actionTitle: `${
                                isActive > 0 ? "Désactivé" : "Activé"
                              } l'article ${
                                item.translation && item.translation[0]?.name
                              }`,
                              number: 5,
                            })
                          )
                        }
                      >
                        <span>{isActive > 0 ? icons.noEye : icons.eye}</span>
                      </div>
                      <div
                        className="btn success"
                        title="Modifier"
                        onClick={() =>
                          dispatch(
                            setOpenModal({
                              actionName: "updateData",
                              data: {
                                id: item.id,
                                uuid: item.uuid,
                                categId: item.categId,
                                prix: item.prix,
                                name: translation && translation[0]?.name,
                                description:
                                  item.translation &&
                                  item.translation[0]?.description,
                                pathImage: item.path_image_web,
                              },
                            })
                          )
                        }
                      >
                        <span>{icons.edit}</span>
                      </div>

                      <div
                        className="btn danger"
                        title="Supprimer"
                        onClick={() =>
                          dispatch(
                            setOpenModal({
                              actionName: "deleteContent",
                              data: item,
                              actionTitle: `suppression de l'article ${
                                item.translation && item.translation[0]?.name
                              }`,
                              number: 2,
                            })
                          )
                        }
                      >
                        {/* <img src={images.deleted} alt="delete article" /> */}
                        <span>{icons.deleted}</span>
                        {/* <span>Supprimer</span> */}
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {loading && <Skelton n={2} />}
      {!loading && fxData.rows && fxData.rows.length < 1 && (
        <TedEmpty description={"Aucun article disponible"} icon={icons.empty} />
      )}
      <PaginationItems
        itemsPerPage={10}
        dataLength={fxData.count}
        changePage={getData}
      />
    </div>
  );
};

export default ListArticles;
