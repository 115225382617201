const DATA_DASHBOARD = [
  {
    id: 1,
    number: 0,
    name: "Articles",
    key: "articles",
  },
  {
    id: 2,
    number: 0,
    name: "Commandes",
    key: "commandes",
  },
  {
    id: 3,
    number: 0,
    name: "Vendeurs",
    key: "vendeurs",
  },
  {
    id: 4,
    number: 0,
    name: "Categories",
    key: "categories",
  },
];
const SK_BODY_COMMANDE = [
  {
    tdName: "w-10",
    pName: "sk_checkbox skelton",
  },
  {
    tdName: "",
    pName: "sk_text skelton",
  },
  {
    tdName: "",
    pName: "sk_text skelton",
  },
  {
    tdName: "",
    pName: "sk_text skelton",
  },
  {
    tdName: "",
    pName: "sk_text skelton",
  },
  {
    tdName: "",
    pName: "sk_text skelton",
  },
  {
    tdName: "",
    pName: "sk_text skelton",
  },
  {
    tdName: "",
    pName: "sk_text skelton",
  },
  {
    tdName: "",
    pName: "sk_status skelton",
  },
  {
    tdName: "",
    pName: "sk_actions skelton",
  },
];
const SK_BODY_APPROV = [
  {
    tdName: "",
    pName: "sk_text skelton",
  },
  {
    tdName: "",
    pName: "sk_text skelton",
  },
  {
    tdName: "",
    pName: "sk_text skelton",
  },
  {
    tdName: "",
    pName: "sk_text skelton",
  },
  {
    tdName: "",
    pName: "sk_status skelton",
  },
  {
    tdName: "",
    pName: "sk_text skelton",
  },
  {
    tdName: "",
    pName: "sk_text skelton",
  },
  {
    tdName: "",
    pName: "sk_text skelton",
  },
  {
    tdName: "",
    pName: "sk_actions skelton",
  },
];
const SK_BODY_LIST_ARTICLE = [
  {
    tdName: "",
    pName: "sk_text skelton",
  },
  {
    tdName: "",
    pName: "sk_text skelton",
  },
  {
    tdName: "",
    pName: "sk_text skelton",
  },
  {
    tdName: "",
    pName: "sk_text skelton",
  },
  {
    tdName: "",
    pName: "sk_status skelton",
  },

  {
    tdName: "",
    pName: "sk_status skelton",
  },
];
export default {
  DATA_DASHBOARD,
  SK_BODY_COMMANDE,
  SK_BODY_APPROV,
  SK_BODY_LIST_ARTICLE,
};
