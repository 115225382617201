import React, { useCallback } from "react";
import "./HeaderPager.css";
import { icons } from "../../../constant";
// import { useGlobalContext } from "../../../global-state/context/context";
// import { useGetLocalStorage } from "../../../hooks/useLocalStorage";
import { useDispatch } from "react-redux";
import { setOpenModal } from "../../../global-state/redux";

const HeaderPager = ({ title, btnTitle, showBtn }) => {
  const dispatch = useDispatch();

  return (
    <div className="header-pager">
      <div>
        <h2>{title}</h2>
      </div>
      <div>
        {showBtn && (
          <div
            className="btn-add"
            onClick={() => dispatch(setOpenModal({ actionName: "createData" }))}
          >
            {icons.add}
            <span>{btnTitle}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderPager;
